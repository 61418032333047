import { httpAxiosV4 } from "../../../gateways/Axios";



export async function getVehiclesByStream(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `panel/vehicles`,
        "POST",
        payload
    );
    return data;
}

export async function ctrlStreamCam(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `ctrlStreamIothubJimi`,
        "POST",
        payload
    );
    return data;
}

export async function getEvents(payload: itemReqEvents): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `events/list-events-cam`,
        "POST",
        payload
    );
    return data;
}


export interface iParamsCamIotHub {
    imei: string
    tipo: string
    channel: string
    action: string
}

// types.ts
export interface VehicleCardProps {
    plate: string;
    nickname: string;
    vehicle: string;
    owner: string;
    updatedAt: string;
    status: string;
    signal: number;
    battery: number;
    voltage: number;
    connections: number;
    ignition: boolean;
    lock: boolean;
    address: string;
    odometer: number;
    cardStatus: 'warning' | 'success' | 'error';
}


export interface itemReqEvents {
    lastregister: number
    idclient: number
    idVehicle: number
    dtStart: string
    dtEnd: string
}


export interface iEventReport {
    id: number;
    idveiculo: number;
    idcliente: number;
    idempresa: number;
    idmotorista: number;
    idviagem: number;
    iddisp: number;
    idcell: number;
    lat: string;
    lng: string;
    start_lat: string;
    start_lng: string;
    evento: number;
    dlastevento: string; // ISO date string
    sevento: string;
    devento: string; // ISO date string
    tevento: string; // duration in HH:MM:SS format
    duraction: number;
    dist: number;
    dist_gps: number;
    ocioso: number;
    status: string;
    dsync: string; // ISO date string
    source: number;
    param1: string;
    param2: string | null;
    param3: string | null;
    apelido: string;
    isrecalc: number;
    isnew: number;
    event_cam: number;
    files_stream: string;
    create_at: string | null; // ISO date string or null
    delete_at: string | null; // ISO date string or null
    update_at: string | null; // ISO date string or null
    is_sync: number;
    dt_evt: string; // date string
    dt_old_evt: string; // date string
    icone: string | null;
    nome: string | null;
    driver_avatar: string;
    driver_name: string;
}
