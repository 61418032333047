import React from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Tab, Box } from '@mui/material';
import CommandsCreatorPage from './views/CommandsCreatorPage';

const CommandsPage: React.FC = () => {


    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };



    return (
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                    <Tab label="Configurações" value="1" />
                    <Tab label="Enviar Comandos" value="2" />
                    <Tab label="Histórico" value="2" />
                </TabList>
            </Box>
            <div>
                <TabPanel value="1"><CommandsCreatorPage /></TabPanel>
                <TabPanel value="2"><CommandsCreatorPage /> </TabPanel>
            </div>
        </TabContext>
    )

}
export default CommandsPage