


import { MarkerWithLabel } from "@googlemaps/markerwithlabel";
import '../Styles/MapStyles.css';
import { EventsData } from "../repository/MapsRepo";
import { fi } from "date-fns/locale";
import { format } from "date-fns";

function createMarker(googleMaps: typeof google.maps, map: google.maps.Map, event: EventsData): MarkerWithLabel {

    const eventIconsOld: { [key: string]: string } = {
        'ign_on': 'ign_on_dark2.png',
        'ign_off': 'ignOf64.png',
        'earthquake': 'earthquake.png',
        // Adicione mais tipos de eventos e seus respectivos ícones aqui
    };

    const eventIcons = (event: string) => {

        switch (event.toLocaleUpperCase()) {
            case 'IGN':
                return 'evt_ign_dark.png';
            case 'DB_ABRUPT_TURN':
                return 'evt_abrupt_turn_dark.png';
            case 'DB_ACCELERATION':
                return 'evt_acceleration_dark.png';
            case 'DB_BRAKING':
                return 'evt_braking_dark.png';
            case 'DB_SPEEDING':
                return 'evt_70km_dark.png';
            case 'VS_COLLISION':
                return 'evt_collision_dark.png';
            default:
                return 'evt_unknow_dark.png';
        }
    };


    const color = event.status === 'ON' ? 'green' : 'orange';
    const labelContent = `
        <div style="position: relative; width: 40px; height: 40px;">                
                <img src="assets/icons_alerts/${eventIcons(event.sevento)}" style=" z-index: 0; width: 32px; height: 32px; position: absolute; top: 4px; left: 4px;" />
            <div class="custom-label-events">
                ${event.sevento}
            </div>
        </div> 
    `;

    const icone = {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 0,
        fillColor: color,
        fillOpacity: 1.0,
        strokeColor: color,
        strokeWeight: 2,
        rotation: 0,
        anchor: new google.maps.Point(0, 4.5),
    };
    const marker = new MarkerWithLabel({
        position: { lat: parseFloat(event.lat), lng: parseFloat(event.lng) },
        map: map,
        icon: icone,
        labelContent: labelContent,
        labelAnchor: new googleMaps.Point(-20, -20),
        labelClass: "custom-marker", // the CSS class for the label
        //labelInBackground: false
    });

    const getIconPath = (event: string) => {
        switch (event) {
            case 'ignition_on':
                return '/assets/icons_alerts/ign_on.png';
            case 'ignition_off':
                return '/assets/icons_alerts/ignOff64.png';
            // Adicione mais cases conforme necessário
            default:
                return '/assets/icons_alerts/ign_on_dark.png';
        }
    };
    /*
        const iconPath = getIconPath(event.sevento);
        const marker = new google.maps.Marker({
            position: { lat: parseFloat(event.lat), lng: parseFloat(event.lng) },
            map: map,
            icon: {
                url: iconPath,
                scaledSize: new google.maps.Size(32, 32), // Ajuste o tamanho conforme necessário
            },
            title: "adadad", // Usar título para mostrar o conteúdo do label no hover
        });*/

    // Add click listener to open InfoWindow
    const files = event.files_stream.split(',');
    const videoFile1 = `https://dvr.maxtracer.com.br/playiot/${files[0]}`;
    const videoFile2 = `https://dvr.maxtracer.com.br/playiot/${files[1]}`;

    const formattedDate = format(new Date(event.devento), 'dd/MM/yyyy HH:mm:ss');

    let contentInfoWindow =
        `<div style="z-index:99999999; min-width: 250px; background-color: #333; color: white; padding: 10px; border-radius: 10px; ">
             <div style="display: flex; align-items: center;">
                    <div style="position: relative; width: 70px; height: 70px;">                
                        <img src="assets/icons_alerts/${eventIcons(event.sevento)}" style="z-index: 0; width: 64px; height: 64px; position: absolute; top: 4px; left: 4px;" />
                    </div>
                    <div style="margin-left: 10px;">
                        <h3>${event.apelido}</h3>
                        <p>${event.sevento}</p>
                        <p>Data e Hora: ${formattedDate}</p>
                    </div>
                </div>
      `
    contentInfoWindow += files[0] ? ` 
                <div>  
                    <video controls autoplay  width="380" >
                            <source src="${videoFile1}" type="video/mp4" />
                            Your browser does not support the video tag.
                    </video>   
                </div>` : '<div></div>';
    contentInfoWindow += files[1] ? ` 
                <div>  
                    <video controls autoplay  width="380" >
                            <source src="${videoFile2}" type="video/mp4" />
                            Your browser does not support the video tag.
                    </video>   
                </div>` : '<div></div>';
    contentInfoWindow += `</div>`

    const newInfoBubble = new (window as any).InfoBubble({
        map: map,
        content: contentInfoWindow,
        position: marker.getPosition(),
        shadowStyle: 1,
        padding: 0,
        backgroundColor: '#333',
        borderRadius: 10,
        arrowSize: 10,
        borderWidth: 1,
        borderColor: '#444',
        disableAutoPan: false,
        hideCloseButton: false,
        arrowPosition: 30,
        backgroundClassName: 'phoney',
        arrowStyle: 2,
    });



    //setInfoBubble(newInfoBubble);

    /*const infowindow = new googleMaps.InfoWindow({
        content: contentInfoWindow,
    });
*/
    marker.addListener('click', () => {
        newInfoBubble.open(map, marker);
    });

    return marker;
}

export default createMarker;