import { VehicleData } from "../../StreamCam/StreamInterfaces";
import { MarkerWithLabel } from "@googlemaps/markerwithlabel";
import '../Styles/MapStyles.css';
import ReactDOM from "react-dom";
import VehicleMapComp from "../components/VehicleMapComp";


function createCustomMarker(map: google.maps.Map, vehicle: VehicleData): MarkerWithLabel {
    const googleMaps = google.maps
    const color = vehicle.ign === 'ON' ? 'green' : 'orange';
    const rotation = vehicle.gpscursor;
    const isArrow = vehicle.gpsvel > 1
    const labelContent = `
        <div style="position: relative; width: 40px; height: 40px;">
            <div style=" width: 40px; height: 40px; background-color: ${color}; border-radius: 50%; position: absolute; top: 0; left: 0;">
                
                <img src="assets/icons_maps/${vehicle.icone}.png" style=" z-index: 0; width: 32px; height: 32px; position: absolute; top: 4px; left: 4px;" />
            </div>
            <div class='custom-label-events'>
                ${vehicle.apelido}
            </div>
        </div> 
    `;

    const icone = {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: isArrow ? 6 : 0,
        fillColor: color,
        fillOpacity: 1.0,
        strokeColor: color,
        strokeWeight: 2,
        rotation: rotation,
        anchor: new google.maps.Point(0, 4.5),
    };

    const marker = new MarkerWithLabel({
        position: { lat: parseFloat(vehicle.gpslat), lng: parseFloat(vehicle.gpslng) },
        map: map,
        icon: icone,
        labelContent: labelContent,
        labelAnchor: new googleMaps.Point(-20, -20),
        labelClass: "custom-marker", // the CSS class for the label
        //labelInBackground: false
    });

    //const formattedDate = format(new Date(vehicle.date), 'dd/MM/yyyy HH:mm');
    const driverAvatar = `${process.env.REACT_APP_DRIVER_AVATAR_PATH}/${vehicle.DriverAvatar}`;

    const driverInfo = vehicle.DriverName && vehicle.DriverAvatar ? `
        <div style="display: flex; align-items: center; margin-top: 10px;">
            <img src="${driverAvatar}" alt="Driver Avatar" style="width: 64px; height: 64px; border-radius: 50%; margin-right: 10px;" />
            <div>
                <p style="margin: 0;">${vehicle.DriverName}</p>
                <p style="margin: 0;">ID: ${vehicle.id_driver}</p>
            </div>
        </div>
    ` : `
        <p>Motorista não identificado</p>
    `;

    const contentInfoBubble = `
        <div style="z-index: 99999999; background-color: #333; color: white; padding: 10px; border-radius: 10px;">
            <div style="display: flex; align-items: center; padding-top: 10px;">
                <div style="position: relative; width: 70px; height: 70px;">
                <img src="assets/icons_maps/${vehicle.icone}.png" style=" width: 32px; height: 32px; position: absolute; top: 4px; left: 4px;" />            
                 </div>
                <div style="margin-left: 10px;">
                    <h3 style="margin: 0;">${vehicle.model_vehicle}</h3>
                    <p style="margin: 0;">${vehicle.vehicle_placa}</p>
                    <p style="margin: 0;">Data e Hora: </p>
                </div>
            </div>
            ${driverInfo}
        </div>
    `;
    /*const container = document.createElement('div');

    ReactDOM.render(<VehicleMapComp vehicleData={vehicle} />, container);*/

    const newInfoBubble = new (window as any).InfoBubble({
        map: map,
        content: contentInfoBubble,
        position: marker.getPosition(),
        shadowStyle: 1,
        padding: 0,
        backgroundColor: '#333',
        borderRadius: 10,
        arrowSize: 10,
        borderWidth: 1,
        borderColor: '#444',
        disableAutoPan: false,
        hideCloseButton: false,
        arrowPosition: 30,
        backgroundClassName: 'phoney',
        arrowStyle: 2,
    });

    marker.addListener('click', () => {
        newInfoBubble.open(map, marker);
    });


    return marker;
}

export default createCustomMarker;