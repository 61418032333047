import React from 'react';
import BarChart from '../../../components/graphics/BarCharts';
import { expensesDashboard, getExpenseDashboad, iCategoryResume } from '../repository/ExpensesRepo';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { styled } from 'styled-components';
import PieChart from '../../../components/graphics/PieCharts';
import CategoryCardList from '../components/CategoryCardList';
import { ContainerInfo, ContainerTable, SearchAndSelectContainer } from '../style/StyleExpenses';
import ButtonRefresh from '../../../components/datagridComp/ButtonRefresh';
import DateFilterDashboard, { dtFilter } from '../components/DateFilterDashboard';

const ContainerInline = styled.div`
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 10px;`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 800px;
    height: 450px;
    padding: 20px;
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    margin: 5px;
`;

interface BarChartData {
    label: string;
    value: number;
    id: number;
}


const DashboardExpensesPage: React.FC = () => {
    const { addNotification } = useNotifications();
    const { user } = useSelector(selectUser);
    const [dataVehicle, setDataVehicle] = React.useState<BarChartData[]>([]);
    const [dataDriver, setDataDriver] = React.useState<BarChartData[]>([]);
    const [dataCategory, setDataCategory] = React.useState<BarChartData[]>([]);
    const [dataCategoryResume, setDataCategoryResume] = React.useState<iCategoryResume[]>([]);
    const [dtFilter, setDtFilter] = React.useState<dtFilter>({ lastreg: 1000 });



    async function getinfoVehicles() {
        try {

            let payload: expensesDashboard = {
                idClient: user.idcliente,
                section: 'totalExpensesByVehicle',
                startDate: dtFilter.dtStart ?? '',
                endDate: dtFilter.dtEnd ?? ''
            }
            const result = await getExpenseDashboad(payload);
            let data = result.map((item: any) => {
                return {
                    label: item.veiculo_placa,
                    value: item.total_amount,
                    id: item.id
                }
            })
            setDataVehicle(data)
        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }
    }
    async function getInfoDriver() {
        try {
            let payload: expensesDashboard = {
                idClient: user.idcliente,
                section: 'totalExpensesByDriver',
                startDate: dtFilter.dtStart ?? '',
                endDate: dtFilter.dtEnd ?? ''
            }
            const result = await getExpenseDashboad(payload);
            let data = result.map((item: any) => {
                let firstName = item.drivername.split(' ')[0];
                return {
                    label: item.drivername,
                    value: item.total_amount,
                    id: item.id
                }
            });
            setDataDriver(data)
        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }
    }

    async function getInfoCategory() {
        try {
            let payload: expensesDashboard = {
                idClient: user.idcliente,
                section: 'expenseBreakdown',
                startDate: dtFilter.dtStart ?? '',
                endDate: dtFilter.dtEnd ?? ''
            }
            const result = await getExpenseDashboad(payload);
            let data = result.map((item: any) => {
                return {
                    label: item.category,
                    value: item.total_amount,
                    id: item.id
                }
            })
            console.log(result)
            setDataCategoryResume(result)
            setDataCategory(data)
        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }
    }

    const getRegistros = () => {
        if (dtFilter.dtStart === undefined || dtFilter.dtEnd === undefined) {
            console.log('Data de inicio e fim não informadas')
            return
        }
        getInfoDriver()
        getinfoVehicles()
        getInfoCategory()
    }
    /*
        React.useEffect(() => {
            getRegistros()
        }, [])*/

    React.useEffect(() => {
        getRegistros()
    }, [dtFilter])




    return (
        <>
            <>
                <SearchAndSelectContainer>
                    <DateFilterDashboard onDateChange={setDtFilter} />

                </SearchAndSelectContainer>

            </>
            <CategoryCardList categories={dataCategoryResume} />
            <ContainerInline>
                <Container>
                    <h1>Total de gastos por Veículos</h1>
                    <BarChart data={dataVehicle} />
                </Container>
                <Container>
                    <h1>Total de gastos por Motorista</h1>
                    <BarChart data={dataDriver} />
                </Container>
            </ContainerInline>
            <Container>
                <h1>Total de gastos por Categoria</h1>
                <PieChart data={dataCategory} />
            </Container>
        </>
    )

}
export default DashboardExpensesPage