// VehicleEquipComp.tsx
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignal, faBatteryHalf, faCarBattery, faSatellite, faKey, faLock, faUnlock, faBatteryFull, faBatteryThreeQuarters, faBatteryQuarter, faBatteryEmpty } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../../components/datagridComp/Tooltip';
import * as timeago from 'timeago.js';
import pt_BR from 'timeago.js/lib/lang/pt_BR';
import { EventsData, TrajectData } from '../repository/MapsRepo';
import { formatDateToScreenString } from '../../../utils/Converter';

// Registra a localidade portuguesa
timeago.register('pt_BR', pt_BR);

interface IconProps {
  isAlert?: boolean;
  color?: string;
}

interface TextProps {
  isColor?: boolean;
  color?: string;
}

const getStatusColor = (status: 'warning' | 'success' | 'error'): string => {
  switch (status) {
    case 'warning':
      return '#FFA500'; // Laranja
    case 'success':
      return '#28a745'; // Verde
    case 'error':
      return '#dc3545'; // Vermelho
    default:
      return '#ddd'; // Padrão
  }
};


const CardContainer = styled.div<{ cardStatus: 'warning' | 'success' | 'error' }>`
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 6px;
  width: 299px;
  font-family: Arial, sans-serif;
  background-color: #fff;
  border-top: 5px solid ${({ cardStatus }) => getStatusColor(cardStatus)};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

`;
const BoxHeader = styled.div`
  margin-left: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

const Title = styled.h2`
  font-size: 14px;
  font-weight: bold;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Subtitle = styled.h3`
  font-size: 12px;
  color: #666;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InfoTxRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-top: 1px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DriverInfo = styled.div`
  font-size: 12px;
  color: #666;
  margin-top: 4px;
`;

const RowIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const ContainerInfoTx = styled.div`
font-size: 12px;
 display: flex;
  align-items: center;
  justify-content: space-around;

`

const ContainerIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 8px;
`;

const Value = styled.span<TextProps>`
  font-size: 12px;
  margin-top: -4px;
  font-weight: bold;
  color: ${({ isColor, color }) => (isColor ? color : '#242424')};
`;

const Status = styled.div<{ color?: string }>`
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => (props.color ? props.color : '#1b1b1b')};
`;

const Divider = styled.div`
  border-bottom: 1px solid #c5c5c5;
  margin: 1px 0;
`;

const Address = styled.div`
  margin-top: 4px;
  font-size: 12px;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Odometer = styled.div`
  margin-top: 4px;
  font-size: 14px;
  font-weight: bold;
  color: red;
`;



const Icon = styled(FontAwesomeIcon) <IconProps>`
  font-size: 18px;
  color: ${({ isAlert, color }) => (isAlert ? color : '#7E7E7E')};
`;
const IconBat = styled(FontAwesomeIcon) <{ color: string }>`
font-size: 16px;
color: ${({ color }) => color};
`;


const IconInlinne = styled(FontAwesomeIcon) <IconProps>`
  font-size: 14px;
  color: ${({ isAlert, color }) => (isAlert ? color : '#7E7E7E')};
  margin-right: 4px;
  min-width: 20px;
`;


const IconContainer = styled.div`
    position: relative;
    width: 40px;
    height: 40px;
`;

const EventImage = styled.img`
    z-index: 0;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 4px;
    left: 4px;
`;

const CustomLabel = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-weight: bold;
    color: #FFF; /* Ajuste de cor conforme necessário */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Para melhor contraste */
`;

interface VehicleEquipCompProps {
  itemData: EventsData;
}

const getBatteryIconAndColor = (batteryPercentage: number) => {
  if (batteryPercentage > 75) {
    return { iconBatBck: faBatteryFull, colorBatBck: 'green' };
  } else if (batteryPercentage > 50) {
    return { iconBatBck: faBatteryThreeQuarters, colorBatBck: 'limegreen' };
  } else if (batteryPercentage > 25) {
    return { iconBatBck: faBatteryHalf, colorBatBck: 'orange' };
  } else if (batteryPercentage > 0) {
    return { iconBatBck: faBatteryQuarter, colorBatBck: 'red' };
  } else {
    return { iconBatBck: faBatteryEmpty, colorBatBck: 'darkred' };
  }
};

const getSignalColor = (signalStrength: number) => {
  if (signalStrength > 75) {
    return 'green';
  } else if (signalStrength > 50) {
    return 'limegreen';
  } else if (signalStrength > 25) {
    return 'orange';
  } else if (signalStrength > 0) {
    return 'red';
  } else {

    return 'darkred';
  }
};

const getCardStatus = (status: boolean) => {
  return status ? 'success' : 'error';
}

const checkIsOnline = (lastUpdate: string) => {
  if (!lastUpdate) return false;
  const lastUpdateDate = new Date(lastUpdate).getTime();
  const now = new Date().getTime();
  const diff = Math.floor((now - lastUpdateDate) / 1000);
  return diff < 3600;
}

const EventsComp: React.FC<VehicleEquipCompProps> = ({ itemData }) => {

  const eventIcons = (event: string) => {

    switch (event.toLocaleUpperCase()) {
      case 'IGN':
        return 'evt_ign_dark.png';
      case 'DB_ABRUPT_TURN':
        return 'evt_abrupt_turn_dark.png';
      case 'DB_ACCELERATION':
        return 'evt_acceleration_dark.png';
      case 'DB_BRAKING':
        return 'evt_braking_dark.png';
      case 'DB_SPEEDING':
        return 'evt_70km_dark.png';
      case 'VS_COLLISION':
        return 'evt_collision_dark.png';
      default:
        return 'evt_unknow_dark.png';
    }
  };


  return (
    <CardContainer cardStatus={'success'}>
      <InfoTxRow>
        <ContainerInfoTx >
          {formatDateToScreenString(itemData.devento)}
        </ContainerInfoTx>
      </InfoTxRow>
      <Divider />
      <IconContainer>
        <EventImage src={`assets/icons_alerts/${eventIcons(itemData.sevento)}`} alt={itemData.sevento} />
      </IconContainer>
      <Divider />
    </CardContainer>
  );
};

export default EventsComp;
