// EventCard.tsx
import React from 'react';
import styled from 'styled-components';
import { itemAlerts } from '../repository/AlertsRepo';
import { iReduxMap } from '../../../interfaces/interfaces';
import { ActionMaps } from '../../../utils/values';
import { changeMap } from '../../../redux/mapSlice';
import { useDispatch } from 'react-redux';

type AlertsProps = {
    alert: itemAlerts;
    onSelect: (id: number) => void;
    isSelected: boolean;
};

const Card = styled.div<{ isSelected: boolean }>`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 8px;
  width: calc(100% - 16px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  background-color: ${({ isSelected }) => (isSelected ? '#e7f3ff' : 'white')};
`;

const TitleCheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 2px;
`;

const Data = styled.span`
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
`;

const Points = styled.span<{ show: boolean }>`
  display: ${({ show }) => (show ? 'inline' : 'none')};
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;

const Priority = styled.span<{ level: number }>`
  /*background-color: ${({ level }) => (level === 1 ? 'lightgreen' : level === 2 ? 'yellow' : 'red')};*/
  /*color: ${({ level }) => (level === 3 ? 'white' : 'black')};*/
  color: black;
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;
const Detail = styled.div`
  margin-bottom: 4px;
`;

const DetailWithIcons = styled.div`
  font-size: 12px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 8px; // Espaço entre ícones e texto
`;

// Placeholder para os ícones. Substitua estas linhas pelos componentes de ícone reais.
const VehicleIcon = styled.span`
cursor: pointer;
  &:before {
    content: "🚗"; // Placeholder para ícone de veículo
  }
`;

const DriverIcon = styled.span`
cursor: pointer;
  &:before {
    content: "👤"; // Placeholder para ícone de motorista
  }
`;

const Separator = styled.div`
  height: 1px;
  background-color: #e0e0e0; // Escolha uma cor que combine com o design do seu card
  margin: 4px 0; // Ajuste as margens conforme necessário para o layout
`;

const InfoButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


const MapIcon = styled.span`
  margin-right: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
  &:before {
    content: '📌'; // Placeholder para ícone de mapa
  }
`;


const AlertsCard: React.FC<AlertsProps> = ({ alert, onSelect, isSelected }) => {

    const dispatch = useDispatch()

    function removeHTMLTags(str: string): string {
        return str.replace(/<[^>]*>?/gm, '');
    }


    const viewInMap = () => {

        let mapConfig: iReduxMap = {
            mapEvtClick: false,
            resetMap: false,
            action: ActionMaps.SHOW_MARKER,
            ender: '',
            marker: {
                position: { lat: Number(alert.lat), lng: Number(alert.lng) },
                isDragable: false
            }
        }
        dispatch(changeMap(mapConfig))
    }

    return (
        <Card isSelected={isSelected}>
            <TitleCheckboxContainer>
                <Title>{alert.title}</Title>
                <input
                    type="checkbox"
                    checked={isSelected}
                    onClick={(e) => e.stopPropagation()} // Impede que o clique no checkbox dispare o evento de clique do card
                    onChange={() => onSelect(alert.id)} // Altera o estado de seleção quando o checkbox é modificado
                />
            </TitleCheckboxContainer>
            <Separator />
            <InfoButtonContainer>
                <div>
                    <Data title='Data do Evento'>{new Date(alert.dtevt_at).toLocaleString()}</Data>
                    <Priority title='Prioridade' level={alert.priority}>{['', '🟢 Baixa', '🟣 Média', '🔴 Alta'][alert.priority]}</Priority>
                    <Points title='Pontos perdidos do motorista' show={alert.points > 0}>Pontos: ⛔ {alert.points}</Points>
                </div>
                <div onClick={() => viewInMap()}><MapIcon title='Ver no Mapa'>Mapa </MapIcon></div>
            </InfoButtonContainer>
            <DetailWithIcons>
                Cód: {alert.idevt} |
                <VehicleIcon title='Dados do Veículo' />{alert.vehicle} |
                <DriverIcon title='Dados do Motorista' />{alert.DriverName}
            </DetailWithIcons>
            <Separator />
            <Detail>{removeHTMLTags(alert.descr)}</Detail>
            {/* Exibir mais detalhes conforme necessário */}
        </Card>
    );
};

export default AlertsCard;
