import React from 'react';
import styled from 'styled-components';

interface SpeedIndicatorProps {
    speed: number;
    range1: number;
    range2: number;
    range3: number;
}

const SpeedWrapper = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  min-width: 50px;
  border-radius: 50%;
  border: 5px solid ${({ color }) => color};
  background-color: #fff;
  font-family: Arial, sans-serif;
  position: relative;
`;

const SpeedText = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;
const StopText = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
`;

const SpeedLabel = styled.div`
  font-size: 10px;
  margin-top: -8px;
  text-align: center;
`;

const SpeedIndicator: React.FC<SpeedIndicatorProps> = ({ speed, range1, range2, range3 }) => {
    let color = '';

    if (speed === 0) {
        return (
            <SpeedWrapper color="gray">
                <StopText>STOP</StopText>
            </SpeedWrapper>
        );
    }

    if (speed < range1) {
        color = 'green';
    } else if (speed >= range1 && speed < range2) {
        color = 'yellow';
    } else if (speed >= range3) {
        color = 'red';
    } else {
        color = 'orange'; // Para a faixa intermediária entre range2 e range3
    }

    return (
        <SpeedWrapper color={color}>
            <SpeedText>{speed}</SpeedText>
            <SpeedLabel>km/h</SpeedLabel>
        </SpeedWrapper>
    );
};

export default SpeedIndicator;
