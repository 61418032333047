import React, { } from 'react';
import { styled } from 'styled-components';
import { EventsData, TrajectData } from '../repository/MapsRepo';
import EventsComp from '../components/EventsComp';
import TrajectsComp from '../components/TrajectsComp';
import { MapConfig } from '../utilsMap/mapContext';
import { TAB_HISTORY_EVENTS, TAB_HISTORY_TRAJETOS } from '../utilsMap/ConstMaps';

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px; /* Espaçamento entre as datas */
  padding: 5px;
  background-color: #f0f0f0; /* Cor de fundo suave */
  border-radius: 8px; /* Bordas arredondadas */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para um efeito elevado */
  font-family: 'Arial', sans-serif;
  font-size: 0.8rem;
  color: #333;

  .date {
    padding: 5px 10px;
    background-color: #fff; /* Cor de fundo das datas */
    border-radius: 4px; /* Bordas arredondadas das datas */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra para um efeito elevado */
  }
`;

interface HistoryProps {
    EventsItens: EventsData[];
    PositionsItens: TrajectData[];
}


const TabHistory: React.FC<HistoryProps> = ({ EventsItens, PositionsItens }) => {
    const { tabHistoryActive } = MapConfig();
    return (
        <>
            {/*<DateContainer>
                <span className="date">{formatDateToScreenDayJs(dateStartFilterMap)}</span>
                <span className="date">{formatDateToScreenDayJs(dateEndFilterMap)}</span>
            </DateContainer>*/}

            {tabHistoryActive === TAB_HISTORY_EVENTS && (
                EventsItens.map((item, index) =>
                    <EventsComp key={index} itemData={item} />
                )
            )}
            {tabHistoryActive === TAB_HISTORY_TRAJETOS && (
                PositionsItens.map((item, index) =>
                    <TrajectsComp key={index} itemData={item} />
                )
            )}


        </>
    );
};

export default TabHistory;
