import React, { useEffect, useState } from 'react';
import DeliveryProgressChart from '../Components/DeliveryProgressChart';
import { styled } from 'styled-components';
import DeliveryProgressChartGeral from './DeliveryProgressChartGeral';
import { getRankingApi } from '../repository/DeliveryRepo';


const deliveryDataList = [
    { id: 1, num_opened: 5, num_canceled: 2, num_finished: 3, DriverName: "Fernando", veiculo_placa: "ABC-1234", descr: "Entrega Rápida", dt_create: "2024-08-07T13:20:52.000Z", dt_finished: null },
    { id: 2, num_opened: 3, num_canceled: 1, num_finished: 6, DriverName: "João", veiculo_placa: "DEF-5678", descr: "Carga Especial", dt_create: "2024-08-07T09:20:52.000Z", dt_finished: null },
    { id: 3, num_opened: 8, num_canceled: 0, num_finished: 2, DriverName: "Maria", veiculo_placa: "GHI-9101", descr: "Entrega Noturna", dt_create: "2024-08-06T10:20:52.000Z", dt_finished: "2024-08-07T15:20:52.000Z" },
    { id: 4, num_opened: 0, num_canceled: 0, num_finished: 8, DriverName: "Maria", veiculo_placa: "GHI-9101", descr: "Entrega Noturna", dt_create: "2024-08-06T10:20:52.000Z", dt_finished: "2024-08-07T15:20:52.000Z" },
];

const CardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: flex-start;
`;

let intervalMap: any

const RankingDeliveryView: React.FC = () => {


    const [selectedDelivery, setSelectedDelivery] = useState<number | null>(null);
    const [ranking, setRanking] = useState<any[]>([]);

    const handleSelect = (id: number) => {
        setSelectedDelivery(id);
    };
    // Calcular o total geral
    const totalTarefas = ranking.reduce((acc, data) => acc + data.num_opened + data.num_canceled + data.num_finished, 0);
    const totalFinalizadas = ranking.reduce((acc, data) => acc + data.num_finished, 0);
    const totalCanceladas = ranking.reduce((acc, data) => acc + data.num_canceled, 0);


    const getRanking = async () => {
        const result = await getRankingApi();
        console.log(result);
        setRanking(result);
    }

    React.useEffect(() => {

        getRanking()
        intervalMap = setInterval(() => {
            getRanking()
        }, 5000)


        //componentUnAmount()
        return () => {
            console.log("Destruct RankingView")
            clearInterval(intervalMap)
        }
    }, [])

    return (
        <div>
            <DeliveryProgressChartGeral
                totalTarefas={totalTarefas}
                totalFinalizadas={totalFinalizadas}
                totalCanceladas={totalCanceladas}
            />

            <CardsContainer>
                {ranking.map((deliveryData) => (
                    <DeliveryProgressChart
                        key={deliveryData.id}
                        deliveryData={deliveryData}
                        onClick={() => handleSelect(deliveryData.id)}
                        selected={selectedDelivery === deliveryData.id}
                    />
                ))}
            </CardsContainer>
        </div>
    );

}
export default RankingDeliveryView