import React, { PropsWithChildren, useEffect, useRef } from 'react';
import styled from 'styled-components';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5locales_pt_Br from "@amcharts/amcharts5/locales/pt_BR";

// Interface for data type
interface BarChartData {
    label: string;
    value: number;
    id: number;
}

// Styled component for the chart container
const ChartContainer = styled.div`
  width: 100%;
  height: 500px;
`;

const BarChart: React.FC<PropsWithChildren<{ data: BarChartData[] }>> = ({ data }) => {
    const chartRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (chartRef.current) {
            const root = am5.Root.new(chartRef.current);
            root.locale = am5locales_pt_Br;

            // Configurações de formatação para o real brasileiro
            root.numberFormatter.setAll({
                numberFormat: "#.###,00a", // Formato básico com duas casas decimais
                bigNumberPrefixes: [
                    { "number": 1e3, "suffix": " mil" },
                    { "number": 1e6, "suffix": " milhões" },
                    { "number": 1e9, "suffix": " bilhões" }
                ],
                smallNumberPrefixes: [
                    { "number": 1e-3, "suffix": "m" },
                    { "number": 1e-6, "suffix": "µ" }
                ]
            });

            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            const chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: true,
                panY: false,
                wheelX: "zoomX",
            }));

            let xRenderer = am5xy.AxisRendererX.new(root, {
                minGridDistance: 5,
                minorGridEnabled: true
            });
            xRenderer.labels.template.setAll({
                rotation: -90,
                centerY: am5.p50,
                centerX: 0,
                paddingRight: 10,
                oversizedBehavior: "truncate",
                maxHeight: 100,
                ellipsis: "..."
            });
            xRenderer.grid.template.set("visible", false);


            const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "label",
                renderer: xRenderer,
                tooltip: am5.Tooltip.new(root, {}),
                tooltipText: "{label}",
            }));

            const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {})
            }));



            const series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: "Total",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                categoryXField: "label",
                fill: am5.color(0xFF9900),
                stroke: am5.color(0xFFBB33),
                calculateAggregates: true,
                tooltip: am5.Tooltip.new(root, {}),
                legendLabelText: "[{stroke}]{name}[/]: [bold #888]{categoryX}[/]",
                legendRangeLabelText: "[{stroke}]{name}[/]",
                legendValueText: "[bold {stroke}]{valueY}[/]",
                legendRangeValueText: "[{stroke}]{valueYClose}[/]",
            }));

            series.data.processor = am5.DataProcessor.new(root, {
                numericFields: ["value"],
                dateFields: ["id"]
            });


            let legend = chart.children.push(am5.Legend.new(root, {
                marginBottom: 30,  // Espaçamento do topo para empurrar a legenda para baixo
            }));
            legend.data.setAll(chart.series.values);



            series.get("tooltip")?.label.set("text", "[bold]{name}[/]{valueX.formatDate()}: {valueY}")

            // Add cursor
            chart.set("cursor", am5xy.XYCursor.new(root, {
                behavior: "zoomXY",
                xAxis: xAxis
            }));

            series.data.setAll(data);
            xAxis.data.setAll(data);

            series.columns.template.setAll({
                fillOpacity: 0.5,
                strokeWidth: 2,
                cornerRadiusTL: 5,
                cornerRadiusTR: 5,
                width: am5.percent(40)
            });

            //add Label in bar
            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 0, // Colocar na base da barra
                    sprite: am5.Label.new(root, {
                        text: "{valueYWorking.formatNumber('#,###.00')}",
                        fontSize: 14,
                        fontWeight: "bold",
                        fill: am5.color(0x000000),
                        rotation: -90,
                        centerY: am5.p50, // Centralizar verticalmente
                        dx: 0, // Deslocar para fora da barra horizontalmente
                        dy: -10, // Deslocar para cima para ficar fora da barra
                        populateText: true,
                    })
                });
            });

            return () => {
                root.dispose();
            };
        }
    }, [data]);

    return <ChartContainer ref={chartRef} />;
};

export default BarChart;
