import React from 'react';
import styled from 'styled-components';

interface VehicleIconMapProps {
  driverAvatar?: string;
  vehicleIcon: string;
  color?: string;
}

const Container = styled.div`
  position: relative;
  width: 46px;
  height: 46px;
`;

const BackgroundCircle = styled.div<{ color: string }>`
  width: 46px;
  height: 46px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
`;

const Image = styled.img`
      z-index: 0;
    width: 42px;
    height: 42px;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 50%;
`;

const Label = styled.div`
  position: absolute;
  top: 45px;
  width: 100%;
  text-align: center;
  font-size: 12px;
`;

const VehicleIconMap: React.FC<VehicleIconMapProps> = ({ driverAvatar, vehicleIcon, color = 'gray' }) => {
  const driverAvatarPath = driverAvatar ? `${process.env.REACT_APP_DRIVER_AVATAR_PATH}/${driverAvatar}` : null;
  const iconPath = `assets/icons_maps/${vehicleIcon}.png`;

  return (
    <Container>
      <BackgroundCircle color={color} />
      <Image src={driverAvatarPath || iconPath} />
    </Container>
  );
};

export default VehicleIconMap;
