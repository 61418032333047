// VehicleEquipComp.tsx
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignal, faBatteryHalf, faCarBattery, faSatellite, faKey, faLock, faUnlock, faWifi, faBatteryFull, faBatteryThreeQuarters, faBatteryQuarter, faBatteryEmpty, faTowerBroadcast } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import Tooltip from '../../../components/datagridComp/Tooltip';
import TimeAgo from 'timeago-react';
import * as timeago from 'timeago.js';
import pt_BR from 'timeago.js/lib/lang/pt_BR';
import VehicleIconMap from './VehicleIconMap';
import { VehicleData } from '../../StreamCam/StreamInterfaces';
import SpeedIndicator from './SpeedIndicator';

// Registra a localidade portuguesa
timeago.register('pt_BR', pt_BR);

interface IconProps {
    isAlert?: boolean;
    color?: string;
}

interface TextProps {
    isColor?: boolean;
    color?: string;
}

const getStatusColor = (status: 'warning' | 'success' | 'error'): string => {
    switch (status) {
        case 'warning':
            return '#FFA500'; // Laranja
        case 'success':
            return '#28a745'; // Verde
        case 'error':
            return '#dc3545'; // Vermelho
        default:
            return '#ddd'; // Padrão
    }
};


const CardContainer = styled.div<{ cardStatus: 'warning' | 'success' | 'error' }>`
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 6px;
  width: 320px;
  font-family: Arial, sans-serif;
  background-color: #fff;
  border-top: 5px solid ${({ cardStatus }) => getStatusColor(cardStatus)};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

`;
const BoxHeader = styled.div`
  margin-left: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
`;

const Title = styled.h2`
  font-size: 14px;
  font-weight: bold;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Subtitle = styled.h3`
  font-size: 12px;
  color: #666;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InfoTxRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-top: 1px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DriverInfo = styled.div`
  font-size: 12px;
  color: #666;
  margin-top: 4px;
`;

const RowIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContainerInfoTx = styled.div`
font-size: 12px;
 display: flex;
  align-items: center;
  justify-content: start;

`

const ContainerIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 8px;
`;

const Value = styled.span<TextProps>`
  font-size: 12px;
  margin-top: -4px;
  font-weight: bold;
  color: ${({ isColor, color }) => (isColor ? color : '#242424')};
`;

const Status = styled.div<{ color?: string }>`
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => (props.color ? props.color : '#1b1b1b')};
`;

const Divider = styled.div`
  border-bottom: 1px solid #c5c5c5;
  margin: 1px 0;
`;

const Address = styled.div`
  margin-top: 4px;
  font-size: 12px;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Odometer = styled.div`
  margin-top: 4px;
  font-size: 14px;
  font-weight: bold;
  color: red;
`;



const Icon = styled(FontAwesomeIcon) <IconProps>`
  font-size: 18px;
  color: ${({ isAlert, color }) => (isAlert ? color : '#7E7E7E')};
`;
const IconBat = styled(FontAwesomeIcon) <{ color: string }>`
font-size: 16px;
color: ${({ color }) => color};
`;


const IconInlinne = styled(FontAwesomeIcon) <IconProps>`
  font-size: 14px;
  color: ${({ isAlert, color }) => (isAlert ? color : '#7E7E7E')};
  margin-right: 4px;
  min-width: 20px;
`;

interface VehicleEquipCompProps {
    vehicleData: VehicleData;
}

const getBatteryIconAndColor = (batteryPercentage: number) => {
    if (batteryPercentage > 75) {
        return { iconBatBck: faBatteryFull, colorBatBck: 'green' };
    } else if (batteryPercentage > 50) {
        return { iconBatBck: faBatteryThreeQuarters, colorBatBck: 'limegreen' };
    } else if (batteryPercentage > 25) {
        return { iconBatBck: faBatteryHalf, colorBatBck: 'orange' };
    } else if (batteryPercentage > 0) {
        return { iconBatBck: faBatteryQuarter, colorBatBck: 'red' };
    } else {
        return { iconBatBck: faBatteryEmpty, colorBatBck: 'darkred' };
    }
};

const getSignalColor = (signalStrength: number) => {
    if (signalStrength > 75) {
        return 'green';
    } else if (signalStrength > 50) {
        return 'limegreen';
    } else if (signalStrength > 25) {
        return 'orange';
    } else if (signalStrength > 0) {
        return 'red';
    } else {

        return 'darkred';
    }
};

const getCardStatus = (status: boolean, ignOn: boolean) => {
    return status ? ignOn ? 'success' : 'warning' : 'error';
}

const checkIsOnline = (lastUpdate: string) => {
    if (!lastUpdate) return false;
    const lastUpdateDate = new Date(lastUpdate).getTime();
    const now = new Date().getTime();
    const diff = Math.floor((now - lastUpdateDate) / 1000);
    return diff < 3600;
}

const VehicleMapComp: React.FC<VehicleEquipCompProps> = ({ vehicleData }) => {
    const ignitionStatus = vehicleData.ign === 'OFF' ? 'Desligado' : 'Ligado';
    const blockStatus = vehicleData.iblock === '1' ? 'Veículo Bloqueado' : 'Veículo Desbloqueado';
    const { iconBatBck, colorBatBck } = getBatteryIconAndColor(Number(vehicleData.batdisp));
    const colorSig = getSignalColor(Number(vehicleData.sig));
    const statusTx = checkIsOnline(vehicleData.txdtEquip)
    const statusPing = checkIsOnline(vehicleData.dtPing)
    const neverPing = vehicleData.dtPing === null || vehicleData.dtPing === undefined;
    const neverUpdate = vehicleData.txdtEquip === null || vehicleData.txdtEquip === undefined;
    const ignOn = vehicleData.ign === 'ON';





    return (
        <CardContainer cardStatus={getCardStatus(statusTx, ignOn)}>
            <Row>
                <Header>
                    <VehicleIconMap vehicleIcon={vehicleData.icone} driverAvatar={vehicleData.DriverAvatar} />
                    <BoxHeader>
                        <Title>{vehicleData.vehicle_placa} ({vehicleData.apelido})</Title>
                        <Tooltip text={vehicleData.dtPing}><ContainerInfoTx >
                            <IconInlinne isAlert={statusPing} color='green' icon={faTowerBroadcast} />
                            <Status color={statusPing ? 'green' : 'red'}>{statusPing ? 'Ping ' : neverPing ? 'Nunca Conectou ' : 'Sem Ping '}
                                {vehicleData.dtPing && <TimeAgo datetime={vehicleData.dtPing} locale="pt_BR" />}
                            </Status>
                        </ContainerInfoTx>
                        </Tooltip>
                    </BoxHeader>
                </Header>
                {ignOn && <SpeedIndicator speed={vehicleData.gpsvel} range1={60} range2={100} range3={120} />}
            </Row>
            <DriverInfo>{vehicleData.DriverName}</DriverInfo>
            <Divider />
            {vehicleData.address && <Address>{vehicleData.address}</Address>}
        </CardContainer>
    );
};

export default VehicleMapComp;
