import React, { PropsWithChildren, useEffect, useRef } from 'react';
import styled from 'styled-components';
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";

// Interface for data type
interface PieChartData {
    label: string;
    value: number;
}

interface IComponentDataItem {
    // Your other properties
    index: number;
}

// Styled component for the chart container
const ChartContainer = styled.div`
  width: 100%;
  height: 500px;
`;

const PieChart: React.FC<PropsWithChildren<{ data: PieChartData[] }>> = ({ data }) => {
    const chartRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (chartRef.current) {
            const root = am5.Root.new(chartRef.current);

            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            const chart = root.container.children.push(
                am5percent.PieChart.new(root, {
                    layout: root.verticalLayout,
                    radius: am5.percent(95),
                    innerRadius: am5.percent(50)
                }));

            const series = chart.series.push(am5percent.PieSeries.new(root, {
                valueField: "value",
                categoryField: "label"
            }));

            // Define a color set with shades of orange
            let colorSet = am5.ColorSet.new(root, {
                colors: [
                    am5.color(0xFFA500), // Orange
                    am5.color(0xFF8C00), // Dark Orange
                    am5.color(0xFF7F50), // Coral
                    am5.color(0xFF4500)  // Orange Red
                ]
            });

            series.slices.template.setAll({
                tooltipText: "{category}: {valuePercent.formatNumber('#.0')}%",
                cornerRadius: 5
            });

            series.set("colors", am5.ColorSet.new(root, {
                colors: [
                    am5.color(0xFFA500), // Orange
                    am5.color(0xFF8C00), // Dark Orange
                    am5.color(0xFF7F50), // Coral
                    am5.color(0xFF4500)  // Orange Red
                ]
            }));

            series.data.setAll(data);

            // Create legend
            const legend = chart.children.push(am5.Legend.new(root, {}));
            legend.data.setAll(series.dataItems);

            // Add tooltip to the series
            series.set("tooltip", am5.Tooltip.new(root, {
                labelText: "{category}: {value}"
            }));

            series.labels.template.setAll({
                visible: false
            });

            series.ticks.template.setAll({
                visible: false
            });

            return () => {
                root.dispose();
            };
        }
    }, [data]);



    return <ChartContainer ref={chartRef} />;
};

export default PieChart;
