
import RoutesComp from './RoutesComp';

import "./Delivery.css"
import "../../styles/DataGrid.css"
import ListDestComp from './ListDestComp';
import ListTaskComp from './ListTaskComp';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React from 'react';
import { Tab, Box } from '@mui/material';
import DeliveryPainelView from './View/DeliveryPanelView';
import { DeliveryProvider } from './utilsDelivery/DeliveryProvider';
import RankingDeliveryView from './View/RankingDeliveryView';


function Delivery() {

    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };



    return (
        <DeliveryProvider>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                        <Tab label="Progresso" value="1" />
                        <Tab label="Roteiros" value="2" />
                        <Tab label="Pontos" value="3" />
                        <Tab label="Painel" value="4" />
                        <Tab label="Tarefas" value="5" />
                    </TabList>
                </Box>
                <div>
                    <TabPanel value="1"><RankingDeliveryView /></TabPanel>
                    <TabPanel value="2"><RoutesComp /></TabPanel>
                    <TabPanel value="3"><ListDestComp /> </TabPanel>
                    <TabPanel value="4"><DeliveryPainelView /> </TabPanel>
                    <TabPanel value="5"><ListTaskComp /></TabPanel>
                </div>
            </TabContext>
        </DeliveryProvider>
    );
}

export default Delivery;