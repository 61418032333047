

import { FormGroup, FormControlLabel, Checkbox, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import DateFilterEvents from '../../StreamCam/Components/DateFilterEvents';
import { useConfig } from '../../../Config/configContext';
import DateFilterMap from './DataFilterMap';

interface IOption {
    value: string;
    label: string;
}

const optionsStatus: IOption[] = [
    { value: '0', label: 'Todos' },
    { value: '10', label: 'Acima da Velocidade 10Km/h' },
    { value: '40', label: 'Acima da Velocidade 40Km/h' },
    { value: '30', label: 'Acima da Velocidade 30Km/h' },
    { value: '50', label: 'Acima da Velocidade 50Km/h' },
    { value: '60', label: 'Acima da Velocidade 60Km/h' },
    { value: '70', label: 'Acima da Velocidade 70Km/h' },
    { value: '80', label: 'Acima da Velocidade 80Km/h' },
    { value: '90', label: 'Acima da Velocidade 90Km/h' },
    { value: '100', label: 'Acima da Velocidade 100Km/h' },
    { value: '110', label: 'Acima da Velocidade 110Km/h' },
    { value: '120', label: 'Acima da Velocidade 120Km/h' },
    { value: '130', label: 'Acima da Velocidade 130Km/h' },

];

const OpcoesHistory: React.FC = () => {
    const { LoadEventsInReportMap, LoadTrajectsInReportMap, speedLimitShowPositions, updateConfig } = useConfig();
    //const [selectValue, setSelectValue] = React.useState<string>(zoomMap.toString());
    const handleDateChange = (value: any) => {
        updateConfig({ speedLimitShowPositions: value });
        if (value === 'intervalo') {
            return;
        }

    }

    const handleLoadEventsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateConfig({ LoadEventsInReportMap: event.target.checked });
    };

    const handleLoadTrajectsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateConfig({ LoadTrajectsInReportMap: event.target.checked });
    };

    return (
        <>
            <DateFilterMap onDateChange={() => { }} />
            <h1>Ao carregar Histórico</h1>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={LoadEventsInReportMap}
                            onChange={handleLoadEventsChange}
                        />
                    }
                    label="Carregar Eventos"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={LoadTrajectsInReportMap}
                            onChange={handleLoadTrajectsChange}
                        />
                    }
                    label="Carregar Trajetos"
                />
            </FormGroup>
            <FormControl sx={{ m: 0, width: 280 }}>
                <InputLabel id="demo-multiple-chip-label">Exibir Pontos</InputLabel>
                <Select
                    labelId="demo-simple-select-autowidth-label"
                    value={speedLimitShowPositions}
                    onChange={(e) => handleDateChange(e.target.value)}
                    label="Selecione o período"
                >
                    {optionsStatus.map((item) => (
                        <MenuItem
                            key={item.value}
                            value={item.value}
                        >
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    )

}
export default OpcoesHistory