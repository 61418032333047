import React from 'react';
import Toolbar from './components/TabBarMap/ToolBar';
import { MapProvider } from './utilsMap/mapContext';
import MapTabContainer from './Views/MapTabContainer';

const MapPage: React.FC = () => {
    return (
        <MapProvider >
            <Toolbar />
            <MapTabContainer />
        </MapProvider>
    );
};

export default MapPage;
