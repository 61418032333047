import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/lottiefiles/exclamation.json';
import confirmData from '../../assets/lottiefiles/confirmed.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { styled } from 'styled-components';


export const ErrorMessage = styled.p`
  color: red;
  font-size: 0.8rem;
  margin-top: -1px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;



export type typeMethods = "CONFIRMED" | "QUESTION" | "INFO";


interface ConfirmationDialogProps {
    message?: string;
    type: typeMethods;
    onCancel?: () => void;
    onConfirm?: (reason?: string) => void;
    requireJustification?: boolean;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ type, message, onCancel, onConfirm, requireJustification }) => {
    const [justification, setJustification] = React.useState('');
    const [errorJustification, setErrorJustification] = React.useState('');

    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: type === 'QUESTION' ? animationData : confirmData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const handleConfirm = () => {
        if (requireJustification) {
            if (justification == null) {
                setErrorJustification("Você precisa informar o motivo!")
                return
            }
            if (justification.trim().length < 10) {
                setErrorJustification("Digite no minímo 10 caracteres")
                return
            }
            setErrorJustification("")
            onConfirm?.(justification);
        } else {
            onConfirm?.();
        }
    };

    return (
        <div className="flex flex-col items-center justify-center p-4 space-y-6 max-w-96">
            <div className="w-full max-w-sm">
                <Lottie options={lottieOptions} height={150} width={150} />
            </div>
            <p className="text-center text-lg font-semibold mb-6 mx-4">
                {message}
            </p>
            {requireJustification && (
                <>
                    <textarea
                        className="w-full max-w-md p-2 border-2 border-gray-300 rounded-lg"
                        rows={3}
                        placeholder="Justificativa"
                        value={justification}
                        onChange={(e) => setJustification(e.target.value)}
                    ></textarea>
                    {errorJustification && (
                        <ErrorMessage>
                            <FontAwesomeIcon icon={faExclamationCircle} /> {errorJustification}
                        </ErrorMessage>

                    )}
                </>
            )}
            {type === 'QUESTION' && <div className="flex justify-around w-full max-w-md">
                <button
                    className="border-2 border-red-500 text-red-500 uppercase py-2 px-4 rounded hover:bg-red-500 hover:text-white transition-colors"
                    onClick={onCancel}>
                    Cancelar
                </button>
                <button
                    className="border-2 border-green-500 text-green-500 uppercase py-2 px-4 rounded hover:bg-green-500 hover:text-white transition-colors"
                    onClick={handleConfirm}>
                    Confirmar
                </button>
            </div>}
            {type === 'CONFIRMED' && <div className="flex justify-around w-full max-w-md">
                <button
                    className="border-2 border-green-500 text-green-500 uppercase py-2 px-4 rounded hover:bg-green-500 hover:text-white transition-colors"
                    onClick={handleConfirm}>
                    Fechar
                </button>
            </div>}
        </div>
    );
};


export default ConfirmationDialog;
