import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';
import { VehicleData } from '../StreamInterfaces';
import styled from 'styled-components';
import { WebSocketContext } from '../../../gateways/WebSocket';
import StreamH264Player, { actionStream, iStreamH264 } from '../Components/StreamH264Player';
import { getVehiclesByStream, ctrlStreamCam, iParamsCamIotHub } from '../repository/StreamRepo';
import VehicleEquipComp from '../widgets/VehicleEquipComp';
import { changeMenu } from '../../../redux/appBarSlice';
import { iReduxMap } from '../../../interfaces/interfaces';
import { changeMap } from '../../../redux/mapSlice';
import CustomButtonPlayStop from '../widgets/CustomButtonPlayStop';
import { useNotifications } from '../../../Notifications/NotificationContext';
import axios from 'axios';
import { v4 } from 'uuid';

let interval: any
let isInit = true

const ContainerFlex = styled.div`
  display: flex;
  flex-direction: column; /* Garante que os elementos fiquem em coluna */
  gap: 5px; /* Mantém o espaçamento de 5px entre os elementos */
  background-color: #f5f5f5; /* Cor de fundo */
  padding: 10px; /* Espaçamento interno */
  max-height: 92vH; 
  max-width: 315px;
  overflow-x: initial;
  align-items: center;
`;

const ContainerBlock = styled.div`
  display: flex;
  flex-direction: row; /* Garante que os elementos fiquem em coluna */
  gap: 15px; /* Mantém o espaçamento de 5px entre os elementos */
  background-color: #f5f5f5; /* Cor de fundo */
  padding: 10px; /* Espaçamento interno */
`;
const ContainerCam = styled.div`
  display: flex;
  flex-direction: row; /* Ou 'column' se quiser em coluna */
  flex-wrap: wrap;    /* Permite a quebra de linha */
  gap: 2px; 
  background-color: #f5f5f5;
  padding: 5px;
`;

const ContainerBtns = styled.div`
   display: flex;
  justify-content: center; /* Centraliza os botões horizontalmente */
  align-items: center; /* Centraliza os botões verticalmente */
  gap: 10px; /* Espaço entre os botões */
`;

const ContainerBoxStream = styled.div`
  display: flex;
  flex-direction: column; /* Ou 'column' se quiser em coluna */
  flex-wrap: wrap;    /* Permite a quebra de linha */
  gap: 2px; 
  background-color: #f5f5f5;
  padding: 5px;
`;


const StreamTab: React.FC = () => {

    const { addNotification } = useNotifications();
    const dispatch = useDispatch()
    const ws = useContext(WebSocketContext);
    const { user } = useSelector(selectUser);
    const [rows, setRows] = React.useState<VehicleData[]>([])
    const [vehicleSel, setVehicleSel] = React.useState<VehicleData>()
    const [streamCam, setStreamCam] = React.useState<iStreamH264[]>([]);





    /*  useEffect(() => {
          console.log(ws)
          if (!socket) {
              socket = ws?.socket
              socket.on('862798051106421', (data: any) => {
                  console.log(data)
              });
              //componentUnAmount()
              return () => {
                  socket.off('862798051106421')
                  socket = null
              }
          }
      }, [ws]);*/

    /* useEffect(() => {
         if (!socket) {
 
 
             socket.on('evt_room', (data: any) => {
                 console.log(data)
             });
 
 
             //componentUnAmount()
             return () => {
                 socket.off('GameStatus')
                 socket = null
             }
 
         }
     }, [rows]);*/


    async function getDados() {
        const payload = {
            idcliente: user.idcliente,
            idempresa: user.idempresa,
            nivel: user.nivel
        }
        const result = await getVehiclesByStream(payload);
        let resp: VehicleData[] = result;
        setRows(resp)
    }

    const CtrlInterval = () => {

        console.log("Start interval Stream")
        interval = setInterval(() => {
            console.log("get Dados Panel")
            getDados()
        }, 5000)

    }

    function ShowMap() {
        const WidthMap = 470;
        const HeightMap = 180
        dispatch(changeMenu({ openMap: new Date().getTime() }))
        const windowWidth = window.innerWidth;
        let mapConfig: iReduxMap = {
            mapEvtClick: false,
            resetMap: false,
            height: HeightMap,
            width: WidthMap
        }
        dispatch(changeMap(mapConfig))
    }

    React.useEffect(() => {
        if (isInit) {
            isInit = false;
            CtrlInterval()
            getDados()
            // ShowMap()
        }
        //componentUnAmount()
        return () => {
            console.log("Destruct Stream")
            clearInterval(interval)
            isInit = true;
        }
    }, [])

    const handleSelectVehicle = (vehicle: VehicleData) => {
        setVehicleSel(vehicle)
        let stream: iStreamH264[] = []
        if (vehicle.tipo === 'JC450') {
            for (let i = 1; i <= 5; i++) {
                stream.push({
                    channelName: `CH-${i}`,
                    channel: `${i}`,
                    isPlaying: false,
                    streamUrl: `${process.env.REACT_APP_LIVE_HLS}/${i}/${vehicle.imei}/hls.m3u8`,
                    uniqueKey: v4()
                })
            }

        }
        if (vehicle.tipo === 'JC4xx') {
            for (let i = 0; i <= 1; i++) {
                stream.push({
                    channelName: `CH-${i === 1 ? 'INTERNA' : 'EXTERNA'}`,
                    channel: `${i === 1 ? 'IN' : 'OUT'}`,
                    isPlaying: false,
                    streamUrl: `${process.env.REACT_APP_LIVE_HLS}/live/${i}/${vehicle.imei}/hls.m3u8`,
                    uniqueKey: v4()
                })
            }
        }
        if (vehicle.tipo === 'JC400-WABA') {
            for (let i = 0; i <= 1; i++) {
                stream.push({
                    channelName: `CH-${i === 1 ? 'INTERNA' : 'EXTERNA'}`,
                    channel: `${i === 1 ? 'IN' : 'OUT'}`,
                    isPlaying: false,
                    streamUrl: `${process.env.REACT_APP_LIVE_HLS}/live/${vehicle.imei}/hls.m3u8`,
                    uniqueKey: v4()
                })
            }
        }
        console.log(stream)
        setStreamCam(stream)
    }


    // const vehicle: VehicleData = {
    //     id: 6693,
    //     idveiculo: 7954,
    //     IDN_MOTOR: "",
    //     id_driver: 60250393,
    //     In1Setup: 0,
    //     In2Setup: 0,
    //     In1: "0",
    //     In2: "0",
    //     km_total: 38649.92,
    //     HorimetroEquipTotal: "4681:33:39",
    //     isStop: 1,
    //     dthr_stop: "2024-06-16T16:49:05.000Z",
    //     RFIDNCARD: "",
    //     tipo: "JM-VL03",
    //     HoraVerao: 0,
    //     GMT: 3,
    //     config_txstop: 3600,
    //     config_txign: 60,
    //     icone: "car1",
    //     iddisp: 2024412,
    //     imei: "862476050548576",
    //     sig: "10",
    //     ign: "OFF",
    //     igndt: "2024-06-16 23:07:05",
    //     iblock: "1",
    //     batdisp: "85",
    //     batveic: "0",
    //     txdt: "2024-06-16 13:49:06",
    //     txdtEquip: "2024-06-16 20:54:05",
    //     dtPing: "2024-06-16 16:35:30",
    //     gpsfix: "1",
    //     gpslat: "-16.56181111",
    //     gpslng: "-49.409",
    //     gpsvel: 0,
    //     gpsdt: "2024-06-16 23:06:05",
    //     gpsid: 34967118,
    //     gpsnsat: "6",
    //     gpshdop: "0",
    //     gpscursor: 118,
    //     km_ignon: 0,
    //     km_dia2: 15308.8,
    //     odometer: 0,
    //     vol_tanque: 0,
    //     tanque_total: 0,
    //     ign_on: "542:47:56",
    //     apelido: "PLI-2331",
    //     address: null,
    //     manufacturer_vehicle: "FORD",
    //     model_vehicle: "COURIER 1.6 L/ 1.6 FLEX",
    //     vehicle_placa: "PLI-2331",
    //     DriverName: "Matheus Almeida",
    //     DriverCard: "1213",
    //     DriverAvatar: "6cae8e2d29b5484831964541b8bc2f64.jpg"
    // };


    const handleActionCam = async (params: actionStream) => {
        try {
            if (vehicleSel === undefined) return
            const payload: iParamsCamIotHub = {
                imei: vehicleSel.imei,
                tipo: vehicleSel.tipo,
                channel: params.channel,
                action: params.action
            }
            const result = await ctrlStreamCam(payload);
            console.log(result)
            let msg = params.action === 'ON' ? 'Transmissão Solicitada' : 'Transmissão Encerrada'
            if (params.action === 'ON') {
                updateScreen(params.channel)
            } else {
                handleStatusCam(params)
            }
            addNotification('', msg, 'success');
        } catch (err: any) {
            if (axios.isAxiosError(err) && err.response) {
                const { message } = err.response.data;
                addNotification('Falha ao solicitar Stream', message, 'error');
            } else {
                addNotification('', err.message, 'error');
            }

        }
    }
    const updateScreen = async (channel: string) => {
        setStreamCam(prevStreamCam =>
            prevStreamCam.map(cam => {
                if (cam.channel === channel) {
                    return {
                        ...cam,
                        uniqueKey: v4()
                    };
                }
                return cam;
            })
        );
    }

    const handleStatusCam = async (params: actionStream) => {
        setStreamCam(prevStreamCam =>
            prevStreamCam.map(cam => {
                if (cam.channel === params.channel) {
                    return {
                        ...cam,
                        isPlaying: params.action === 'ON'
                    };
                }
                return cam;
            })
        );
    }

    return (
        <ContainerBlock>

            <ContainerFlex >
                {rows.filter((i: VehicleData) => (i.tipo === "JC400-WABA" || i.tipo === "JC4xx" || i.tipo === "JC450")).map((i: VehicleData) =>
                    <div onClick={() => handleSelectVehicle(i)}><VehicleEquipComp vehicleData={i} /></div>
                )}
            </ContainerFlex >
            {vehicleSel &&
                <ContainerBoxStream>
                    <ContainerBtns>
                        {streamCam.map((x: iStreamH264) =>
                            <CustomButtonPlayStop
                                key={'ctrl' + x.uniqueKey}
                                isPlay={x.isPlaying}
                                title={x.channelName} onClick={() =>
                                    handleActionCam({ channel: x.channel, action: x.isPlaying ? 'OFF' : 'ON' })} />
                        )}
                    </ContainerBtns>
                    <ContainerCam>
                        {streamCam.map((x: iStreamH264) =>
                            <StreamH264Player
                                key={'screen' + x.uniqueKey}
                                channelName={x.channelName}
                                channel={x.channel}
                                streamUrl={x.streamUrl}
                                onClickAction={handleStatusCam}
                            />
                        )}

                    </ContainerCam>
                </ContainerBoxStream>}
        </ContainerBlock>
    )

};

export default StreamTab;
