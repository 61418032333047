import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faClock, faTruck } from '@fortawesome/free-solid-svg-icons';
import TimeAgo from 'timeago-react'; // para exibir o tempo
import { formatDistanceToNow, differenceInHours } from 'date-fns';

interface DeliveryData {
    id: number;
    num_opened: number;
    num_canceled: number;
    num_finished: number;
    DriverName: string;
    veiculo_placa: string;
    descr: string;
    dt_create: string;
    dt_finished?: string | null;
}

interface DeliveryProgressChartProps {
    deliveryData: DeliveryData;
    onClick: () => void;
    selected: boolean;
}

const ProgressBarContainer = styled.div`
    position: relative;
    width: 100%;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
`;

const ProgressBar = styled.div<{ progress: number }>`
    width: ${({ progress }) => progress}%;
    height: 100%;
    background-color: #4caf50;
    transition: width 0.5s ease-in-out;
    border-radius: 5px;
`;

const IndicatorsContainer = styled.div`
    display: flex;
    justify-content: start;
    margin-top: 10px;
    font-size: 13px;
    gap: 20px;
`;

const IndicatorItem = styled.div`
    display: flex;
    align-items: center;
`;

const Card = styled.div<{ selected: boolean }>`
    border: ${({ selected }) => (selected ? '2px solid #4caf50' : '1px solid #e0e0e0')};
    border-radius: 10px;
    padding: 15px;
    margin: 10px;
    width: 500px;
    box-shadow: ${({ selected }) => (selected ? '0 0 10px rgba(0, 0, 0, 0.1)' : 'none')};
    cursor: pointer;
    transition: border 0.3s ease, box-shadow 0.3s ease;
    &:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
`;

const DeliveryProgressChart: React.FC<DeliveryProgressChartProps> = ({ deliveryData, onClick, selected }) => {
    const total = deliveryData.num_opened + deliveryData.num_canceled + deliveryData.num_finished;
    const completed = deliveryData.num_finished + deliveryData.num_canceled;
    const progress = (completed / total) * 100;

    // Função para calcular a duração
    const calculateDuration = () => {
        if (deliveryData.dt_finished) {
            // Se estiver finalizado, calcular a diferença entre dt_finished e dt_create
            return formatDistanceToNow(new Date(deliveryData.dt_finished), { addSuffix: true });
        } else {
            // Se não estiver finalizado, calcular o tempo desde dt_create até agora
            return formatDistanceToNow(new Date(deliveryData.dt_create), { addSuffix: true });
        }
    };

    return (
        <Card onClick={onClick} selected={selected}>
            <h4>
                {deliveryData.DriverName} - {deliveryData.veiculo_placa} ({deliveryData.descr})
            </h4>

            <ProgressBarContainer>
                <ProgressBar progress={progress} />
            </ProgressBarContainer>

            <IndicatorsContainer>
                <IndicatorItem>
                    <span>Total Tarefas: {total}</span>
                </IndicatorItem>
                <IndicatorItem>
                    <FontAwesomeIcon icon={faCheckCircle} color="#4caf50" />
                    <span style={{ marginLeft: '5px' }}>Finalizadas: {deliveryData.num_finished}</span>
                </IndicatorItem>
                <IndicatorItem>
                    <FontAwesomeIcon icon={faTimesCircle} color="#f44336" />
                    <span style={{ marginLeft: '5px' }}>Canceladas: {deliveryData.num_canceled}</span>
                </IndicatorItem>
                <IndicatorItem>
                    <FontAwesomeIcon icon={faTruck} color="#fa7d08" />
                    <span style={{ marginLeft: '5px' }}>Abertas: {deliveryData.num_opened}</span>
                </IndicatorItem>
            </IndicatorsContainer>

            {/*<div style={{ marginTop: '10px', fontSize: '12px', color: '#888' }}>
                Duração: {calculateDuration()}
            </div>*/}
        </Card>
    );
};

export default DeliveryProgressChart;
