import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f9fa;
  animation: ${fadeIn} 1.5s ease-in;
`;

const Content = styled.div`
  text-align: center;
  color: #333;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const Message = styled.p`
  font-size: 1.25rem;
  margin-bottom: 2rem;
`;

const ComingSoonPage: React.FC = () => (
  <Container>
    <Content>
      <Title>Em Breve</Title>
      <Message>Estamos trabalhando em algo incrível. Fique ligado!</Message>
    </Content>
  </Container>
);

export default ComingSoonPage;
