import { styled } from 'styled-components';
import { MapConfig } from '../utilsMap/mapContext';
import MapTab from './MapTab';
import PanelTab from './PanelTab';


const ContentContainer = styled.div`
  padding: 16px;
`;



const MapTabContainer: React.FC = () => {
    const { tabActive } = MapConfig();

    const renderContent = () => {
        switch (tabActive) {
            case 'MAPA':
                return <MapTab />;
            case 'PANEL':
                return <PanelTab />;
            default:
                return null;
        }
    };


    return (

        <ContentContainer>
            {renderContent()}
        </ContentContainer>
    );
};

export default MapTabContainer;