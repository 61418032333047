import { httpAxiosV4 } from "../../../gateways/Axios";



export async function getRankingApi(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `delivery-ranking`,
        "GET"
    );
    return data;
}
export async function getRulesExpenses(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `Expenses-rules-client`,
        "GET"
    );
    return data;
}