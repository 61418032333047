// Drawer.jsx
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAppBar } from '../redux/appBarSlice';
import { selectUser } from '../redux/userSlice';
import { selectCred } from '../redux/credSlice';
import { IMenu, getItensMenu } from '../utils/menu';

const Drawer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState<string>("");

  const [menuItems, setMenuItems] = React.useState<IMenu[]>([]);
  //  const { appbar } = useSelector(selectAppBar);
  const { user } = useSelector(selectUser);
  const { cred } = useSelector(selectCred);

  const handleItemClick = (path: string) => {
    navigate(path);
    setSelectedItem(path)
    window.localStorage.setItem("pathSelected", path)
  };


  const processItensMenu = () => {
    let i = getItensMenu(user.nivel, cred)
    setMenuItems(i)
  }

  React.useEffect(() => {
    setSelectedItem(location.pathname)
    processItensMenu();
  }, [location.pathname])

  return (
    <div className="w-24 h-screen bg-slate-100 flex flex-col items-center">
      {menuItems?.map((item, index) => (
        <div
          key={index}
          className={`w-full text-center p-2 cursor-pointer ${selectedItem === item.path ?
            'bg-slate-200' : ''}`}
          onClick={() => handleItemClick(item.path)}
        >
          <item.Icon className={`mx-auto h-7 w-7 flex-none stroke-orange-200 fill-orange-500`} />
          <code className="text-sm font-bold text-gray-900">{item.title}</code>
        </div>
      ))}
    </div>
  );
};

export default Drawer;
