import React from 'react';


const PanelTab: React.FC = () => {

    return (
        <>
            painel
        </>
    )

}
export default PanelTab