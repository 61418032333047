import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React from 'react';
import { Tab, Box } from '@mui/material';
import StreamTab from './StreamTab';
import ComingSoonPage from '../../ComingSoonPage';
import EventsPage from './EventsPage';


const StreamPage: React.FC = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                        <Tab label="Ao Vivo" value="1" />
                        <Tab label="Histórico" value="2" />
                        <Tab label="Eventos" value="3" />
                    </TabList>
                </Box>
                <div>
                    <TabPanel value="1"><StreamTab /></TabPanel>
                    <TabPanel value="2"><ComingSoonPage /></TabPanel>
                    <TabPanel value="3"><EventsPage /></TabPanel>
                </div>
            </TabContext>
        </>
    );

}
export default StreamPage