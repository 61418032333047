import React from 'react';
import ConfirmationDialog, { typeMethods } from '../../components/datagridComp/ConfirmationDialog';
import { getAlerts, getRulesAlerts, itemRuleAlert, RegisterAlertsInServer } from './repository/AlertsRepo';
import { Column, SelectColumn } from 'react-data-grid';
import ModalComp from '../../components/datagridComp/ModalComp';
import LoadingIndicator from '../../components/datagridComp/LoadingComp';
import DataGridComp from '../../components/datagridComp/DataGridComp';
import ButtonEdit from '../../components/datagridComp/ButtonEdit';
import { BoxIcon, BtnCheckBox, BtnFaIcon, ContainerBarTop, ContainerInfo, ContainerSearch, ContainerTable, InfoBox, PriorityItem, SearchAndSelectContainer } from './style/StyleAlerts';
import SearchField from '../../components/datagridComp/SearchField';
import ActionSelect from '../../components/datagridComp/ActionSelect';
import ColumnSelect from '../../components/datagridComp/ColumnSelect';
import ButtonRefresh from '../../components/datagridComp/ButtonRefresh';
import ButtonAdd from '../../components/datagridComp/ButtonAdd';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/userSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faBell, faCar, faFlag, faRefresh } from '@fortawesome/free-solid-svg-icons';
import FormAlertsCreate from './View/FormAlertsCreate';
import ModalListAlertType, { AlertRow } from './Components/ModalListAlertType';



interface iModelCmd {
    onConfirm: (value?: any) => Promise<void> | void;
    message: string
    title?: string
    requireJustification: boolean
    type: typeMethods

}

const LOADING_CONTENT = 'loading'
const CONFIRMED_ACTION = 'confirm_action'

const optionsActions = [
    { value: 'RE', label: 'Remover Equipamento' },
    { value: 'DELCHIP', label: 'Apagar Chips' }
];

const ConfigAlertsTab: React.FC = () => {

    const { user } = useSelector(selectUser);
    const [isShowForm, setIsShowForm] = React.useState(false);
    const [ConfigAlertEdit, setConfigAlertEdit] = React.useState<itemRuleAlert>();

    //modal
    const [IsOpenSelectType, setIsOpenSelectType] = React.useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const handleClose = () => setIsModalOpen(false);
    const handleCloseModelCmd = () => setModelCmd(undefined);
    const [modalContent, setModalContent] = React.useState<string>('');
    const [modelCmd, setModelCmd] = React.useState<iModelCmd>();


    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<itemRuleAlert[]>([]);
    const [filteredRows, setFilteredRows] = React.useState<itemRuleAlert[]>(rows);
    const [columnsFilter, setColumnsFilter] = React.useState<Column<itemRuleAlert>[]>([]);
    const [visibleColumns, setVisibleColumns] = React.useState<string[]>(columnsFilter.map(column => column.key.toString()));


    const columns: Column<itemRuleAlert>[] = [
        {
            ...SelectColumn,
            width: 150,
        },
        {
            key: 'id',
            name: 'Cód',
            width: 80
        },
        {
            key: 'edit',
            name: 'Ações',
            width: 140,
            renderCell(props: any) {
                return (
                    <>
                        <BtnCheckBox onClick={() => editItem(props.row)}>✏️ Editar</BtnCheckBox></>
                )
            }
        },
        {
            key: 'info',
            name: 'info',
            width: 130,
            renderCell(props: any) {
                return (
                    <div style={{
                        display: 'flex', alignItems: 'center', gap: '6px'
                    }}>
                        <InfoBox title='Smartphones Registrados' onClick={() => handleClickRow(props.row)} >
                            <FontAwesomeIcon icon={faBell} /> {props.row.num_subscribes}
                        </InfoBox >
                        <InfoBox title='Veículos Registrados' onClick={() => handleClickRow(props.row)}>
                            <FontAwesomeIcon icon={faCar} /> {props.row.num_vehicles}
                        </InfoBox>
                        {props.row.tipo === 'CERCA' && <InfoBox title='Cercas Registrados' onClick={() => handleClickRow(props.row)}>
                            <FontAwesomeIcon icon={faFlag} /> {props.row.num_cercas}
                        </InfoBox>}
                    </div >
                )
            }
        },
        {
            key: 'points',
            name: 'Pontos',
            width: 65,
            renderCell(props: any) {
                return (
                    <div style={{
                        display: 'flex', alignItems: 'center', gap: '6px'
                    }}>
                        <InfoBox title='Pontos do Motoristas' onClick={() => handleClickRow(props.row)} >
                            ⛔ {props.row.points}
                        </InfoBox >
                    </div >
                )
            }
        },
        {
            key: 'points_active',
            name: 'Pontos Ativos',
            width: 65,

        },
        {
            key: 'priority',
            name: 'Nível',
            width: 90,
            renderCell(props: any) {
                return (
                    <PriorityItem level={props.row.priority}>{['', '🟢 Baixa', '🟣 Média', '🔴 Alta'][props.row.priority]}</PriorityItem>
                )
            },
        },
        {
            key: 'title',
            name: 'Título',
            width: 150
        },
        // {
        //     key: 'icon',
        //     name: 'Ícone',
        //     width: 100,
        //     // Para renderizar o ícone, você pode precisar de um renderizador customizado
        // },
        // {
        //     key: 'type_alert',
        //     name: 'Tipo de Alerta',
        //     width: 120
        // },
        {
            key: 'descr',
            name: 'Descrição',
            width: 200
        },
        // {
        //     key: 'tipo',
        //     name: 'Tipo',
        //     width: 100
        // },
        {
            key: 'param1',
            name: 'Parâmetro 1',
            width: 150
        },
        {
            key: 'valor',
            name: 'Valor',
            width: 100
        },

        // {
        //     key: 'IsRangerWork',
        //     name: 'Ranger Ativo',
        //     width: 120
        // },
        // {
        //     key: 'semana',
        //     name: 'Semana',
        //     width: 200
        // },

        {
            key: 'evento',
            name: 'Evento',
            width: 100
        },
        // {
        //     key: 'inFavActive',
        //     name: 'Favorito Ativo (In)',
        //     width: 150
        // },
        // {
        //     key: 'inFavAlert',
        //     name: 'Alerta Favorito (In)',
        //     width: 150
        // },
        // {
        //     key: 'inFavTimer',
        //     name: 'Timer Favorito (In)',
        //     width: 150
        // },
        // {
        //     key: 'inFavTol',
        //     name: 'Tolerância Favorito (In)',
        //     width: 150
        // },
        // {
        //     key: 'outFavActive',
        //     name: 'Favorito Ativo (Out)',
        //     width: 150
        // },
        // {
        //     key: 'outFavAlert',
        //     name: 'Alerta Favorito (Out)',
        //     width: 150
        // },
        // {
        //     key: 'outFavTimer',
        //     name: 'Timer Favorito (Out)',
        //     width: 150
        // },
        // {
        //     key: 'outFavTol',
        //     name: 'Tolerância Favorito (Out)',
        //     width: 150
        // },
        // {
        //     key: 'create_at',
        //     name: 'Criado Em',
        //     width: 150
        //     // Pode necessitar de um renderizador para formatar a data
        // }

    ];


    const editItem = (item: itemRuleAlert) => {
        setConfigAlertEdit(item)
        setIsShowForm(true)
    }

    const handleSearch = (searchText: string) => {
        const filtered = rows.filter((person) =>
            Object.values(person).some((value) => {
                const normalizedValue = String(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                const normalizedSearchText = searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                return normalizedValue.includes(normalizedSearchText);
            })
        );
        setFilteredRows(filtered);
    };

    const handleEdit = (row: itemRuleAlert) => {
        // console.log(row)
    }
    const handleClickRow = (row: itemRuleAlert) => {
        // console.log(row)
    }

    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }

    const handleSelectAction = (action: string) => {
        switch (action) {
            /*case 'RE':
                setIsModalOpen(true)
                setModalContent(CONFIRM_REMOVE_EQUIP)
                break
            case 'AF':
                setIsModalOpen(true)
                setModalContent(PROVIDERS_CONTENT)
                break
            case 'SE':
                setIsModalOpen(true)
                setModalContent(EQUIP_CONTENT)
                break
            case 'DELCHIP':
                handleDeleteChip()
                break*/
        }
    };

    async function getDados() {
        try {
            const result = await getRulesAlerts();
            console.log(result)
            setRows(result)
            setFilteredRows(result);
        } catch (e) {
            let error = e as Error
            console.log('[ListTasksDelivery]', error)
        }
    }

    React.useEffect(() => {
        getDados()
    }, [])

    const selectAll = () => {
        const allIds = new Set(filteredRows.map((alert) => alert.id));
        setSelectedRows(allIds);
    };

    const deselectAll = () => {
        setSelectedRows(new Set());
    };

    const handleSelectAlert = async (alert: AlertRow) => {
        let payload = {
            descr: alert.descr,
            alerta_id: alert.id,
            typeAlert: alert.type_alert
        }
        await RegisterAlertsInServer(payload)
        getDados()
    };


    return (
        <>
            {!isShowForm &&
                <>
                    <ContainerTable>
                        <>
                            <SearchAndSelectContainer>
                                <SearchField onSearch={handleSearch} />
                                {/*<ActionSelect
                            options={optionsActions}
                            onSelect={handleSelectAction}
    />*/}
                                <ColumnSelect
                                    columns={columns}
                                    onColumnVisibilityChange={(visibleColumnKeys) => {
                                        setVisibleColumns(visibleColumnKeys);
                                    }}
                                />
                                <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={getDados} >
                                    <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
                                </BtnFaIcon >
                                <BtnFaIcon title='Cadastrar nova regra' onClick={() => setIsOpenSelectType(true)} >
                                    <BoxIcon icon={faAdd} iconColor='green' /> Criar Alerta
                                </BtnFaIcon >


                            </SearchAndSelectContainer>
                            <ContainerInfo>
                                <BtnCheckBox onClick={selectAll}>✅ Selecionar Tudo</BtnCheckBox>
                                <BtnCheckBox onClick={deselectAll}> ❌ Desmarcar Tudo</BtnCheckBox>
                                <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                                <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
                            </ContainerInfo>
                            <DataGridComp
                                rows={filteredRows}
                                columns={columns}
                                selectedRows={selectedRows}
                                visibleColumns={visibleColumns}
                                onRowClick={handleClickRow}
                                onRowSelect={handleRowSelect}
                            />

                        </>
                    </ContainerTable>
                </>}
            {isModalOpen && (
                <ModalComp title="" subtitle="" onClose={handleClose}>
                    {modalContent === LOADING_CONTENT && <LoadingIndicator />}
                    {modalContent === CONFIRMED_ACTION && <ConfirmationDialog type='CONFIRMED' message="Operação realizada com sucesso!" onConfirm={() => handleClose()} onCancel={() => handleClose()} />}
                </ModalComp>
            )}
            {isShowForm && <FormAlertsCreate initialData={ConfigAlertEdit} closeForm={() => {
                setIsShowForm(false)
                getDados()
            }
            } />}
            {<ModalListAlertType
                onRequestClose={() => { setIsOpenSelectType(false) }}
                isOpen={IsOpenSelectType}
                onSelectAlert={handleSelectAlert} />}
        </>
    )

}
export default ConfigAlertsTab