import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FaLock, FaUnlock } from 'react-icons/fa';
import { VehicleData } from '../../StreamCam/StreamInterfaces';
import { checkVehiclsHasOnline } from '../../Map/utilsMap/tools';
import { httpAxiosV4 } from '../../../gateways/Axios';
import { iCommandsConfig, iCommandsOptions, iItemCmdConfig, iPrepareCmd } from '../Repositories/CommandsRepo';

interface CmdBlockCompProps {
    vehicleData: VehicleData;
}

const Container = styled.div`
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    width: 300px;
    background-color: #f9f9f9;
`;

const Message = styled.p`
    font-size: 14px;
    color: #333;
    margin-bottom: 8px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const IconButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    
    &:hover {
        background-color: #0056b3;
    }

    &:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
    }
`;

const CmdBlockComp: React.FC<CmdBlockCompProps> = ({ vehicleData }) => {
    const [actions, setActions] = React.useState<iItemCmdConfig[]>([]);
    const [config, setConfig] = React.useState<iCommandsConfig>();
    const { txdtEquip, ign, gpsvel, tipo, imei } = vehicleData;
    const currentSpeed = Number(gpsvel);
    const ignitionStatus = ign === 'ON';
    const hasSignal = checkVehiclsHasOnline(txdtEquip);

    const getCmdByEquip = async (): Promise<any> => {
        const payload = {
            type_cmd: "BLOCK",
            model_equip: tipo,
        }
        const { data } = await httpAxiosV4<any, iCommandsOptions>(
            `CmdByType`,
            "POST",
            payload
        );
        setActions(data.itens);
        setConfig(data.config);
    }

    const SendCommand = async (payload: iPrepareCmd): Promise<any> => {
        const { data } = await httpAxiosV4<any, iCommandsOptions>(
            `sendCommand`,
            "POST",
            payload
        );
        console.log(data)
    }




    useEffect(() => {
        if (tipo) {
            getCmdByEquip()
        }
    }, []);


    const renderMessages = () => {
        if (currentSpeed > 20) {
            return <Message>⚠️ Risco de bloqueio com veículo em movimento!</Message>;
        }
        if (!ignitionStatus) {
            return <Message>⚠️ Ligue a ignição para evitar que o equipamento entre em modo sleep.</Message>;
        }
        if (!hasSignal) {
            return <Message>⚠️ Comando não será enviado ao veículo de forma imediata devido à falta de sinal.</Message>;
        }
        return <Message>✅ Tudo pronto para enviar o comando.</Message>;
    };

    const onCommandExecute = (action: iItemCmdConfig) => {
        if (!config) return;

        let values = [
            {
                key: action.cmd,
                value: action.value,
            },
        ];
        const payload: iPrepareCmd = {
            imei: imei,
            id_config: config.id,
            id_item: config.id_actions,
            deviceId: '',
            deviceType: 'WEB',
            sintaxe_cmd: config.sitaxe_cmd,
            model_equip: config.model_equip,
            type_cmd: config.type_cmd,
            categ: config.categ,
            values: values
        }
        SendCommand(payload)
    };



    const renderButtons = () => {
        return actions
            .filter(command => command.type_element === 'button' && command.disable === 0)
            .sort((a, b) => a.order_itens - b.order_itens)
            .map(command => (
                <IconButton
                    key={command.id}
                    onClick={() => onCommandExecute(command)}
                    disabled={command.disable === 1}
                >
                    {command.label === 'Bloqueio' ? <FaLock /> : <FaUnlock />}
                    {command.label}
                </IconButton>
            ));
    };

    return (
        <Container>
            {renderMessages()}
            <ButtonContainer>
                {renderButtons()}
            </ButtonContainer>
        </Container>
    );
};

export default CmdBlockComp;
