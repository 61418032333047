import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import dayjs, { Dayjs } from 'dayjs';

type Config = {
    zoomMap: number;
    LoadEventsInReportMap: boolean;
    LoadTrajectsInReportMap: boolean;
    GroupVehiclesInMap: boolean;
    speedLimitShowPositions: number;
    dateStartFilterMap: Dayjs;
    dateEndFilterMap: Dayjs;
    updateConfig: (newConfig: Partial<Config>) => void;
};

const defaultConfig: Config = {
    zoomMap: 12,
    LoadEventsInReportMap: true,
    LoadTrajectsInReportMap: true,
    GroupVehiclesInMap: true,
    speedLimitShowPositions: 30,
    dateStartFilterMap: dayjs(new Date()),
    dateEndFilterMap: dayjs(new Date()),
    updateConfig: () => { },
};

const ConfigContext = createContext<Config>(defaultConfig);

export const useConfig = () => {
    return useContext(ConfigContext);
};

type ConfigProviderProps = {
    children: ReactNode;
};

export const ConfigProvider = ({ children }: ConfigProviderProps) => {
    const [config, setConfig] = useState(defaultConfig);

    useEffect(() => {
        // Carregar configurações do localStorage ao montar
        const loadConfig = () => {
            const localConfig = localStorage.getItem('appConfig');
            if (localConfig) {
                setConfig(JSON.parse(localConfig));
            } else {
                // Salvar configurações padrão no localStorage se não houver registro
                localStorage.setItem('appConfig', JSON.stringify(defaultConfig));
            }
        };

        loadConfig();
    }, []);

    useEffect(() => {
        // Salvar configurações no localStorage sempre que mudarem
        localStorage.setItem('appConfig', JSON.stringify(config));
    }, [config]);

    const updateConfig = (newConfig: Partial<Config>) => {
        setConfig((prevConfig) => ({
            ...prevConfig,
            ...newConfig,
        }));
    };

    return (
        <ConfigContext.Provider value={{ ...config, updateConfig }}>
            {children}
        </ConfigContext.Provider>
    );
};
