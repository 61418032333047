import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';
import { VehicleData } from '../../StreamCam/StreamInterfaces';
import { EventsData, getApiEventos, getApiOneVehicle, getApiTrajetos, getVehiclesByStream, TrajectData } from '../repository/MapsRepo';
import { styled } from 'styled-components';
import VehicleMapComp from '../components/VehicleMapComp';
import VehicleMapCompSmall from '../components/VehicleMapCompSmall';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import createCustomMarker from '../utilsMap/createCustomMarker';
import { FaMapMarkedAlt, FaStreetView } from 'react-icons/fa';
import createMarker from '../utilsMap/createCustomMarkerEvents';
import Sidebar from '../../../components/datagridComp/SideBar';
import OpcoesMap from '../components/OpcoesMap';
import OpcoesHistory from '../components/OpcoesHistory';
import TabHistory from './TabHistory';
import { useConfig } from '../../../Config/configContext';
import { formatDateDayJs } from '../../../utils/Converter';
import IconButtonsMonitor from '../components/IconsButtonMonitor';
import { MapConfig } from '../utilsMap/mapContext';
import { useGoogleMap } from '../../../GoogleMaps/GoogleMapProvider';
import { ALL_VEHICLES, HISTORY_VEHICLE, ONE_VEHICLE, OPTIONS_TRAJETOS, SETTINGS_MAP } from '../utilsMap/ConstMaps';
import ModalComp from '../../../components/datagridComp/ModalComp';
import CmdBlockComp from '../../ComandsCreator/views/CmdBlockComp';

let intervalMap: any
let isInitMpp = true

const ContainerBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  background-color: #f5f5f5;
  padding: 5px;
  height: 85vh; /* Ocupa toda a altura da janela */
`;

const ContainerFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: #f5f5f5;
  padding: 10px;
  max-height: 100%;
  width: 315px; /* Largura fixa para a lista de veículos */
  overflow-y: auto; /* Permitir rolagem vertical se necessário */
  align-items: center;
`;

const ContainerMaps = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Ocupa o restante da largura disponível */
  background-color: #f5f5f5;
  padding: 5px;
  height: 100%; /* Garante que ocupe toda a altura disponível */
`;

const MapContainer = styled.div`
  height: 100%; /* Garante que o mapa ocupe toda a altura do contêiner pai */
  width: 100%; /* Garante que o mapa ocupe toda a largura do contêiner pai */
  position: relative; /* Necessário para posicionar os marcadores */
`;

var actionConfig = ALL_VEHICLES

const MapTab: React.FC = () => {

    const { LoadEventsInReportMap, LoadTrajectsInReportMap, speedLimitShowPositions, dateStartFilterMap, dateEndFilterMap } = useConfig();
    const { searchVehicles, modeActive, actionPressButton, updateConfig } = MapConfig();
    //----------------Maps
    const map = useGoogleMap();
    const mapContainerRef = useRef<HTMLDivElement>(null);

    const markersVehicleRef = useRef<google.maps.Marker[]>([]);
    const markersRef = useRef<google.maps.Marker[]>([]);
    const polyLineRef = useRef<google.maps.Polyline[]>([]);
    const clustererRef = useRef<MarkerClusterer | null>(null);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [markerOneVehicle, setMarkerOneVehicle] = React.useState<google.maps.Marker>();
    //--------------
    const { user } = useSelector(selectUser);
    const [rows, setRows] = React.useState<VehicleData[]>([])
    const [filteredRows, setFilteredRows] = React.useState<VehicleData[]>([])
    const [vehicleSelected, setVehicleSelected] = React.useState<VehicleData | null>(null)
    const vehicleSelectedRef = useRef<VehicleData | null>(vehicleSelected);
    const [trajects, setTrajects] = React.useState<TrajectData[]>([])
    const [eventsData, setEventsData] = React.useState<EventsData[]>([])
    const [isLoaded, setIsLoaded] = React.useState<boolean>(false);

    //sidebar 
    const [sideContent, setSideContent] = React.useState<string>();
    const handleCloseSideBar = () => setSideContent(undefined);
    //modal
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

    async function getAllVehicles() {
        const payload = {
            idcliente: user.idcliente,
            idempresa: user.idempresa,
            nivel: user.nivel
        }
        const result = await getVehiclesByStream(payload);
        let resp: VehicleData[] = result;
        setRows(resp)
        if (searchVehicles.length === 0) setFilteredRows(resp)
    }

    async function getOneVehicle() {
        if (vehicleSelectedRef.current === null) return
        const payload = {
            idvehicle: vehicleSelectedRef.current?.idveiculo,
        }
        const result = await getApiOneVehicle(payload);
        let resp: VehicleData = result;
        if (resp) {
            setVehicleSelected(resp);
        }
    }

    const handleSearch = (searchText: string) => {
        const filtered = rows.filter((person) =>
            Object.values(person).some((value) => {
                const normalizedValue = String(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                const normalizedSearchText = searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                return normalizedValue.includes(normalizedSearchText);
            })
        );
        setFilteredRows(filtered);
    };

    useEffect(() => {
        handleSearch(searchVehicles);
    }, [searchVehicles]);

    useEffect(() => {
        clearMap()
        actionConfig = modeActive
        getDados()
    }, [modeActive]);



    async function getTrajetos(idVehicle: number) {
        const dtStart = formatDateDayJs(dateStartFilterMap)
        const dtEnd = formatDateDayJs(dateEndFilterMap)

        const payload = {
            dtStart,
            dtEnd,
            idVehicle
        };
        const result = await getApiTrajetos(payload);
        let resp: TrajectData[] = result;
        setTrajects(resp)
        updateConfig({ countPositions: resp.length })
    }
    async function getEventos(idVehicle: number) {
        const dtStart = formatDateDayJs(dateStartFilterMap)
        const dtEnd = formatDateDayJs(dateEndFilterMap)

        const payload = {
            dtStart,
            dtEnd,
            idVehicle
        };
        const result = await getApiEventos(payload);
        let resp: EventsData[] = result;
        setEventsData(resp)
        updateConfig({ countEvents: resp.length })
    }

    function getColorBasedOnSpeed(speed: number): string {
        if (speed <= 50) return "#1100ff"; // Verde para velocidade baixa
        if (speed > 50 && speed < 80) return "#ff7300"; // Amarelo para velocidade média
        return "#FF0000"; // Vermelho para velocidade alta
    }

    async function getDados() {
        console.log("Get Dados", actionConfig)
        switch (actionConfig) {
            case ALL_VEHICLES:
                await getAllVehicles()
                break;
            case ONE_VEHICLE:
                await getOneVehicle()
                break;
            default:
                console.log("Action not found")
                break;
        }
    }

    function getHistory() {
        updateConfig({ modeActive: HISTORY_VEHICLE })
        const idVehicle = vehicleSelected?.idveiculo || 0;
        console.log("Get History", idVehicle)
        if (LoadEventsInReportMap) getEventos(idVehicle)
        if (LoadTrajectsInReportMap) getTrajetos(idVehicle)
    }

    const CtrlInterval = () => {

        console.log("Start interval Maps")
        intervalMap = setInterval(() => {
            getDados()
        }, 5000)

    }

    React.useEffect(() => {
        if (isInitMpp) {
            isInitMpp = false;
            CtrlInterval()
            getDados()
            // ShowMap()
        }
        //componentUnAmount()
        return () => {
            console.log("Destruct MapBeta")
            clearInterval(intervalMap)
            isInitMpp = true;
            clearMap()
        }
    }, [])

    useEffect(() => {
        console.log('Map IN Maps Open')
        if (map && mapContainerRef.current) {
            // Associa o mapa ao contêiner específico desta tela
            map.setOptions({
                center: { lat: -15.7801, lng: -47.9292 }, // Ajuste conforme necessário
                zoom: 4,
            });

            // Move o mapa para o contêiner correto
            mapContainerRef.current.appendChild(map.getDiv());
            google.maps.event.trigger(map, 'resize');
            // Initialize MarkerClusterer
            clustererRef.current = new MarkerClusterer({ map });

        }
    }, [map]);

    // useEffect(() => {
    //     const initializeMap = async () => {
    //         try {
    //             const googleMaps = await loadMapApi();
    //             console.log("Google Maps API loaded successfully.");
    //             if (mapRef.current) {
    //                 const map = new googleMaps.Map(mapRef.current, {
    //                     center: { lat: -15.7801, lng: -47.9292 },
    //                     zoom: 4,
    //                     mapTypeControl: true,
    //                     streetViewControl: false,
    //                     rotateControl: true,
    //                     scaleControl: true,
    //                     fullscreenControl: true,
    //                     panControl: true,
    //                     zoomControl: true,
    //                     mapId: 'ec009120bd8c9ea',
    //                     mapTypeControlOptions: {
    //                         style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
    //                         position: google.maps.ControlPosition.BOTTOM_LEFT,
    //                     },
    //                     fullscreenControlOptions: {
    //                         position: google.maps.ControlPosition.TOP_RIGHT,
    //                     },
    //                     gestureHandling: 'greedy',// 'cooperative',//controla o zoom do mapa sem o CTRl
    //                     mapTypeId: google.maps.MapTypeId.ROADMAP,

    //                 });
    //                 map = map;
    //                 // Initialize MarkerClusterer
    //                 clustererRef.current = new MarkerClusterer({ map });
    //                 console.log("Map and MarkerClusterer initialized successfully.");
    //             }
    //         } catch (error) {
    //             console.error('Error loading Google Maps:', error);
    //         }
    //     };

    //     actionNow = ALL_VEHICLES
    //     initializeMap();
    // }, []);


    useEffect(() => {
        if (mapContainerRef.current && rows.length > 0) {
            const bounds = new google.maps.LatLngBounds();

            // Remove existing markers from the cluster
            if (clustererRef.current) {
                clustererRef.current.clearMarkers();
            }

            markersVehicleRef.current.forEach(marker => marker.setMap(null));
            markersVehicleRef.current = [];

            const createMarkers = () => {
                for (const vehicle of rows) {
                    const marker = createCustomMarker(map!, vehicle);
                    markersVehicleRef.current.push(marker);
                    bounds.extend(marker.getPosition()!);
                }

                // Add markers to the cluster
                if (clustererRef.current) {
                    clustererRef.current.addMarkers(markersVehicleRef.current);
                }

                if (isFirstLoad) {
                    map!.fitBounds(bounds);
                    setIsFirstLoad(false);
                }
            };
            createMarkers()
        }
    }, [rows, isFirstLoad]);

    useEffect(() => {
        if (map && vehicleSelected) {
            if (!vehicleSelected) return
            if (markersVehicleRef.current.length > 0) {
                clearMap()
            }
            if (markerOneVehicle) markerOneVehicle.setMap(null)
            const marker = createCustomMarker(map!, vehicleSelected);
            setMarkerOneVehicle(marker);
            map?.setCenter(marker.getPosition()!);
        }
    }, [vehicleSelected]);

    useEffect(() => {
        if (map && trajects.length > 0) {
            const bounds = new google.maps.LatLngBounds();
            const path = trajects.map(t => ({ lat: parseFloat(t.lat), lng: parseFloat(t.lng) }));

            const line = new google.maps.Polyline({
                map: map,
                path,
                geodesic: true,
                strokeColor: '#FF0000',
                strokeOpacity: 1.0,
                strokeWeight: 2,
            });

            polyLineRef.current.push(line);

            for (const point of path) {
                bounds.extend(point);
            }
            map.fitBounds(bounds);

            // Adicionando marcadores com InfoWindow
            trajects.forEach((t) => {
                const position = { lat: parseFloat(t.lat), lng: parseFloat(t.lng) };
                const colorMarker = getColorBasedOnSpeed(t.speed);
                if (t.speed > speedLimitShowPositions) {
                    const marker = new google.maps.Marker({
                        position,
                        map: map,
                        icon: {
                            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                            scale: 3,
                            fillColor: colorMarker,
                            fillOpacity: 0.8,
                            rotation: t.dir,
                            strokeColor: colorMarker,
                            strokeWeight: 2,
                        },
                    });
                    markersRef.current.push(marker);


                    const infoWindow = new google.maps.InfoWindow({
                        content: `
                        <div>
                            <p><strong>Velocidade:</strong> ${t.speed} km/h</p>
                            <p><strong>Latitude:</strong> ${t.lat}</p>
                            <p><strong>Longitude:</strong> ${t.lng}</p>
                            <button onclick="window.open('https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${t.lat},${t.lng}', '_blank')">
                                <i class="${FaStreetView}"></i> Street View
                            </button>
                            <button onclick="window.open('https://www.google.com/maps/search/?api=1&query=${t.lat},${t.lng}', '_blank')">
                                <i class="${FaMapMarkedAlt}"></i> Google Maps
                            </button>
                        </div>
                    `,
                    });

                    marker.addListener('click', () => {
                        infoWindow.open(map, marker);
                    });

                    marker.setMap(map);
                }
            });
        }
    }, [trajects]);

    useEffect(() => {
        if (map && eventsData.length > 0) {

            // Adicionando marcadores com InfoWindow
            eventsData.forEach((t) => {
                if (t.sevento === 'OA_FILE_UPLOADED') return
                const marker = createMarker(window.google.maps, map!, t);
                markersRef.current.push(marker);
            });
        }
    }, [eventsData]);


    const handleSelectVehicle = (vehicle: VehicleData) => {
        clearMap()
        vehicleSelectedRef.current = vehicle;
        setVehicleSelected(vehicle)
        updateConfig({ modeActive: ONE_VEHICLE })
        map!.setCenter({ lat: parseFloat(vehicle.gpslat), lng: parseFloat(vehicle.gpslng) });
        map!.setZoom(15);
    }

    const clearMap = () => {
        markersVehicleRef.current.forEach(marker => marker.setMap(null));
        markersVehicleRef.current = [];
        markersRef.current.forEach(marker => marker.setMap(null));
        markersRef.current = [];
        polyLineRef.current.forEach(line => line.setMap(null));
        polyLineRef.current = [];
        if (clustererRef.current) {
            clustererRef.current.clearMarkers();
        }
    }

    const handleClose = () => {
        clearMap()
        if (markerOneVehicle) markerOneVehicle.setMap(null)
        vehicleSelectedRef.current = null;
        setVehicleSelected(null)
        updateConfig({ modeActive: ALL_VEHICLES })
    };


    const handleIconClick = (action: string) => {
        switch (action) {
            case 'close':
                handleClose()
                break;
            case 'lock':
                setIsModalOpen(true)
                break;
            case 'settings':
                setSideContent(OPTIONS_TRAJETOS)
                break;
            case 'settings-map':
                setSideContent(SETTINGS_MAP)
                break;
            case 'list':
                getHistory()
                break;
            case 'streetView':
                window.open(`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${vehicleSelected?.gpslat},${vehicleSelected?.gpslng}`, '_blank');
                break;
            case 'map':
                window.open(`https://www.google.com/maps/search/?api=1&query=${vehicleSelected?.gpslat},${vehicleSelected?.gpslng}`, '_blank');
                break;
            case 'share':
                console.log('Compartilhar ação');
                break;
            case 'monitoring':
                //setMonitor()
                break;
            case 'clear':
                clearMap()
                break;
            case 'update':
                clearMap()
                getHistory()
                break;
            default:
                console.log('Ação desconhecida');
        }
        updateConfig({ actionPressButton: null })
    };

    useEffect(() => {
        if (actionPressButton)
            handleIconClick(actionPressButton)
    }, [actionPressButton]);

    const menuTop = (
        <IconButtonsMonitor onIconClick={handleIconClick} mode={modeActive} />
    )




    return (
        <>
            <ContainerBlock>

                <ContainerFlex>
                    {vehicleSelected ?
                        <>
                            {modeActive === ONE_VEHICLE && <VehicleMapComp vehicleData={vehicleSelected} />}
                            {modeActive === HISTORY_VEHICLE &&
                                <TabHistory
                                    EventsItens={eventsData}
                                    PositionsItens={trajects} />}


                            {/*<SpeedChart data={trajects} />*/}
                        </>
                        : filteredRows.length ? filteredRows.map((i: VehicleData) =>
                            <div key={i.id} onClick={() => handleSelectVehicle(i)}>
                                <VehicleMapCompSmall vehicleData={i} />
                            </div>
                        ) : <div>Nenhum veiculo</div>
                    }
                </ContainerFlex>
                <ContainerMaps>
                    <MapContainer ref={mapContainerRef}></MapContainer>
                </ContainerMaps>
            </ContainerBlock>
            <Sidebar isOpen={sideContent === OPTIONS_TRAJETOS} onClose={handleCloseSideBar} title='Opções de Trajetos'>
                <OpcoesHistory />
            </Sidebar>
            <Sidebar isOpen={sideContent === SETTINGS_MAP} onClose={handleCloseSideBar} title='Configurações do Mapa'>
                <OpcoesMap />
            </Sidebar>
            {(isModalOpen && vehicleSelected) && (
                <ModalComp title="Comando de Bloqueio" subtitle="" onClose={() => { setIsModalOpen(false) }}>
                    <CmdBlockComp
                        vehicleData={vehicleSelected}
                    />
                </ModalComp>
            )}
        </>
    );
};

export default MapTab;
