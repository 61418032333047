import React from 'react';

interface ModalProps {
  title: string;
  subtitle: string;
  onClose: () => void;
  children: React.ReactNode;
}

const ModalComp: React.FC<ModalProps> = ({ title, subtitle, onClose, children }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" onClick={onClose}>
      <div className="bg-white p-5 rounded-lg shadow-lg " onClick={e => e.stopPropagation()} style={{ minWidth: "350px" }}>
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold">{title}</h2>
          <button onClick={onClose} className="text-3xl">&times;</button>
        </div>
        <p className="mt-4">{subtitle}</p>
        <div className="mt-4">
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalComp;
