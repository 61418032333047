import React from 'react';
import styled from 'styled-components';
import { FaPlay, FaStop } from 'react-icons/fa';

interface CustomButtonProps {
    isPlay: boolean;
    title: string;
    onClick: () => void;
}

const Button = styled.button<{ isPlay: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${({ isPlay }) => (isPlay ? 'red' : 'green')};
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    opacity: 0.8;
  }

  svg {
    margin-right: 10px;
  }
`;

const CustomButtonPlayStop: React.FC<CustomButtonProps> = ({ isPlay, title, onClick }) => {
    return (
        <Button isPlay={isPlay} onClick={onClick}>
            {isPlay && <FaStop />}
            {!isPlay && <FaPlay />}
            {title}
        </Button>
    );
};

export default CustomButtonPlayStop;
