import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React from 'react';
import { Tab, Box } from '@mui/material';
import CobrancasPage from './CobrancasPage';
import WhatsAppPage from './WhatsAppPage';

interface CompProps {
    item: string
}

const FinancePage: React.FC<CompProps> = ({ item }) => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };


    return (
        <>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                        <Tab label="Cobranças" value="1" />
                        <Tab label="WhatsApp" value="2" />
                    </TabList>
                </Box>
                <div>
                    <TabPanel value="1"><CobrancasPage /></TabPanel>
                    <TabPanel value="2"><WhatsAppPage /></TabPanel>
                </div>
            </TabContext>
        </>
    );
}
export default FinancePage